import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountMenu } from "../Components/AccountMenu";
import { MD5 } from "crypto-js";
import Modal from "../Components/Modal";
import Withdraw from "./Account/Withdraw";
import AddUser from "./Account/AddUser";
import { HomeComponent } from "../Components/HomeComponent";
import { CouponCheckHome } from "../Components/CouponCheckHome";
import { OnlineWithdrawal } from "../Components/OnlineWithdrawal";
import { SHOW_MODAL } from "../Redux/types";
import { formatNumber } from "../Utils/helpers";
import PlayerDeposit from "../Components/Modal/PlayerDeposit";
import UserTransfer from "../Components/Modal/UserTransfer";

export const Home = ({ history }) => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [hash, setHash] = useState("");
  const backurl = process.env.REACT_APP_URL;
  const privateKey = process.env.REACT_APP_XPRESS_PRIVATE_KEY;
  const [modal, setModal] = useState(false);
  const [, refresh] = useState();
  const [inputObject, setInput] = useState({});
  const dispatch = useDispatch();
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);

  useEffect(() => {
    document.body.classList.add("defaultlogged", "Logged");
    document.body.classList.remove("default", "Anonymous");
    if (!isAuthenticated) {
      history.push("/Login");
      document.body.classList.remove("defaultlogged", "Logged");
      document.body.classList.add("default", "Anonymous");
    } else {
      setHash(
        MD5(
          `${user.auth_code}10160${backurl}1${user.group}retail${privateKey}`
        ).toString()
      );
    }
  }, [isAuthenticated, user]);

  const toggle = () => {
    setModal(!modal);
    refresh();
  };

  const closeModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        open: false,
      },
    });
  };

  return (
    <>
      <AccountMenu componentClase="homeLoggedMenu" user={user} />
      <div className="homeShop logged">
        <div className="bottoni">
          {user?.role === "Cashier" && (
            <a className="bookBet" href="/Sport/Cashdesk"></a>
          )}
          <a
            className="printSoccer"
            href={`${process.env.REACT_APP_BASEURL}/soccer-print`}
            target="_blank"
          ></a>
          {/* <a id="hl_w_PC_SoccerSpecials" className="printSoccerSpecials new" href="GroupsExt.aspx?IDSport=542" target="_blank"></a> */}
          <a
            className="printToday"
            href={`${process.env.REACT_APP_BASEURL}/today-print`}
            target="_blank"
          ></a>
          {
            <a
              className="printOdds"
              href={`${process.env.REACT_APP_BASEURL}/combo-print`}
              target="_blank"
            ></a>
          }
          <a
            className="liveviewer"
            href="https://live.raimax.bet/"
            target="_blank"
          ></a>
          <a className="live-scores" href="/Sport/Livescore"></a>
          {user?.role === "Shop" && (
            <a
              className="transfer"
              href={`/Account/Transfer/${"internal"}`}
            ></a>
          )}
          {/* <a className="casino" href="#" target="_blank"></a> */}
          {user?.role === "Shop" ? (
            <a
              className="addcash"
              onClick={() =>
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    open: true,
                    title: "ADD CASHIER",
                    component: (
                      <AddUser toggle={closeModal} userType="cashier" />
                    ),
                  },
                })
              }
            ></a>
          ) : (
            <>
              <a
                className="zoroplay-v"
                href={`${process.env.REACT_APP_XPRESS_LAUNCH_URL}?token=${user.auth_code}&game=10160&backurl=${backurl}&mode=1&group=${user.group}&clientPlatform=retail&h=${hash}`}
                target="_blank"
              ></a>
              <a
                className="luckyballs"
                href="https://games.elbet.com/lucky-balls/index.html?referer=online.sportsbookengine.elbet.com"
                target="_blank"
              ></a>
            </>
          )}
          {/* <a className="live-casino" href="#" target="_blank"></a> */}

          {user?.role === "Cashier" && (
            <a
              className="adduser"
              onClick={() =>
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    open: true,
                    title: "ADD USER",
                    component: (
                      <AddUser toggle={closeModal} userType="player" />
                    ),
                  },
                })
              }
            ></a>
          )}
          <a className="live-scores" href="/Sport/Livescore"></a>

          <a
            className="transferUser"
            onClick={() =>
              dispatch({
                type: SHOW_MODAL,
                payload: {
                  open: true,
                  title: "PLAYER TRANSFER",
                  component: <UserTransfer toggle={closeModal} />,
                },
              })
            }
          ></a>
          {user?.role === "Cashier" && (
            <a
              onClick={() =>
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    open: true,
                    title: "ONLINE PLAYER DEPOSIT",
                    component: <PlayerDeposit toggle={closeModal} />,
                  },
                })
              }
              className="online-deposit"
            ></a>
          )}

          {/* <a type="button" className="SimulateGames newOrange" id="SimulateGames" onclick="toggleCouponSimulateVisualization();"></a> */}
          {/* <a className="online-deposit" href="#" target="_blank"></a> */}
        </div>

        {user?.role !== "Cashier" && (
          <div className="right-menu">
            <h3 className="header">Quick Look</h3>
            <div className="quick-look">
              <div>
                <h3>
                  {" "}
                  {user?.balance < 0 && "You owe the company"}
                  {user?.balance > 0 && "The company owes you"}{" "}
                </h3>
                <span>
                  <strong>
                    {" "}
                    {formatNumber(Math.abs(user?.balance))}&nbsp;
                    {SportsbookGlobalVariable.Currency}
                  </strong>{" "}
                  as at {new Date().toLocaleDateString()}
                </span>
              </div>
              <div>
                <h3>Liability threshold:</h3>
                <span>
                  <strong>
                    {formatNumber(
                      Math.abs(SportsbookGlobalVariable?.LiabilityThreshold)
                    )}
                    &nbsp;
                    {SportsbookGlobalVariable.Currency}
                  </strong>
                  {/* as at {new Date().toLocaleDateString()} */}
                </span>
              </div>
              <div>
                <h3>Credit Balance:</h3>
                <span>
                  <strong>
                    {formatNumber(user?.available_balance)}&nbsp;
                    {SportsbookGlobalVariable.Currency}
                  </strong>
                </span>
              </div>
            </div>
            <div className="quick-look">
              <div>
                <h3>Today Player Deposit</h3>
                <span>
                  <strong>
                    {" "}
                    {formatNumber(user?.total_player_deposit)}&nbsp;
                    {SportsbookGlobalVariable.Currency}
                  </strong>{" "}
                </span>
              </div>
              <div>
                <h3>Today Player Withdrawal</h3>
                <span>
                  <strong>
                    {" "}
                    {formatNumber(user?.total_player_withdrawal)}&nbsp;
                    {SportsbookGlobalVariable.Currency}
                  </strong>{" "}
                </span>
              </div>
              <div>
                {/* <h3></h3> */}

                <h3>Today Player Balance</h3>
                <span>
                  <strong>
                    {" "}
                    {formatNumber(user?.total_player_balance)}&nbsp;
                    {SportsbookGlobalVariable.Currency}
                  </strong>{" "}
                </span>
              </div>
            </div>
          </div>
        )}

        {user?.role == "Cashier" && (
          <div className="right-menu">
            <h3 className="header">Quick Look</h3>
            <div className="quick-look"></div>
            <div className="quick-look">
              <div>
                <h3>Today Player Deposit</h3>
                <span>
                  <strong>
                    {" "}
                    {formatNumber(user?.total_player_deposit)}&nbsp;
                    {SportsbookGlobalVariable.Currency}
                  </strong>{" "}
                </span>
              </div>
              <div>
                <h3>Today Player Withdrawal</h3>
                <span>
                  <strong>
                    {" "}
                    {formatNumber(user?.total_player_withdrawal)}&nbsp;
                    {SportsbookGlobalVariable.Currency}
                  </strong>{" "}
                </span>
              </div>
              <div>
                {/* <h3></h3> */}

                <h3>Today Player Balance</h3>
                <span>
                  <strong>
                    {" "}
                    {formatNumber(user?.total_player_balance)}&nbsp;
                    {SportsbookGlobalVariable.Currency}
                  </strong>{" "}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="boxInserimento">
          <div className="check">
            <CouponCheckHome dispatch={dispatch} />

            <OnlineWithdrawal dispatch={dispatch} />
          </div>
        </div>
        <HomeComponent user={user} />
      </div>

      <Modal
        title={"APPROVE WITHDRAWAL"}
        modal={modal}
        toggle={toggle}
        children={<Withdraw toggle={toggle} code={inputObject.withdraw_code} />}
      />
    </>
  );
};
