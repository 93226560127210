import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchReport, handleReport } from "../Services/apis";
import { toast } from "react-toastify";
import { SHOW_MODAL } from "../Redux/types";

const GenerateForm = ({ generateReport }) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [formData, setFormData] = useState({
    date: startDate,
    openingBalance: 0,
    closingBalance: 0,
    cashin: 0,
    cashout: 0,
    expenses: 0,
    normalPayouts: 0,
    normalSales: 0,
    onlinePayouts: 0,
    onlineSales: 0,
    otherPayouts: 0,
    otherSales: 0,
    status: null,
  });

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    fetchBranchReport();
  }, [setFormData.date, startDate]);

  const fetchBranchReport = async () => {
    const report = await fetchReport(startDate);
    if (report.success) {
      setFormData((prev) => ({
        ...prev,
        ...report.data,
        otherSales: 0,
        otherPayouts: 0,
      }));
    }
  };
  const handleBranchReport = async () => {
    const res = await handleReport({ ...formData, date: startDate });
    if (!res.success) {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
    if (res.success) {
      dispatch({ type: SHOW_MODAL, payload: null });
      toast.success(res.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <form
      className="new-entry"
      onSubmit={(e) => {
        e.preventDefault();
        handleBranchReport();
      }}
    >
      <div className="entry-field">
        <label>Date</label>
        <div className="input">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        {/* <input type="number" name="date" onChange={handleChange} /> */}
      </div>
      <div className="entry-field">
        <label>Opening Balance</label>
        <input
          type="number"
          name="openingBalance"
          value={formData.openingBalance}
          readOnly
        />
      </div>
      <div className="entry-field">
        <label>Online Sales</label>
        <input
          type="number"
          name="onlineSales"
          readOnly
          value={formData.onlineSales}
        />
      </div>
      <div className="entry-field">
        <label>Online Payouts</label>
        <input
          type="number"
          name="onlinePayouts"
          value={formData.onlinePayouts}
          readOnly
        />
      </div>

      <div className="entry-field">
        <label>Normal Sales</label>
        <input
          type="number"
          name="normalSales"
          readOnly
          value={formData.normalSales}
        />
      </div>

      <div className="entry-field">
        <label>Normal Payouts</label>
        <input
          type="number"
          value={formData.normalPayouts}
          name="normalPayouts"
          readOnly
        />
      </div>
      {/* <div className="entry-field">
        <label>Virtual Sales</label>
        <input type="number" name="virtualSales" onChange={handleChange} />
      </div> */}
      {/* <div className="entry-field">
        <label>Virtual Payout</label>
        <input type="number" name="virtualSales" onChange={handleChange} />
      </div> */}

      <div className="entry-field">
        <label>Other Sales</label>
        <input
          type="number"
          name="otherSales"
          value={formData.otherSales}
          onChange={handleChange}
        />
      </div>
      <div className="entry-field">
        <label>Other Payouts</label>
        <input
          type="number"
          name="otherPayouts"
          value={formData.otherPayouts}
          onChange={handleChange}
        />
      </div>
      <div className="entry-field">
        <label>Cash In</label>
        <input type="number" name="cashin" readOnly value={formData.cashin} />
      </div>
      <div className="entry-field">
        <label>Cash Out</label>
        <input type="number" name="cashout" readOnly value={formData.cashout} />
      </div>
      <div className="entry-field">
        <label>Expenses</label>
        <input
          type="number"
          name="expenses"
          readOnly
          value={formData.expenses}
        />
      </div>
      <div className="entry-field">
        <label>Status</label>
        <input
          type="text"
          name="expenses"
          readOnly
          value={
            formData.status === 0
              ? "pending"
              : formData.status === 1
              ? "approved"
              : "not recorded"
          }
        />
      </div>
      <div className="entry-field">
        <label>Closing Balance</label>
        <input
          type="number"
          name="closingBalance"
          value={
            Number(formData.closingBalance) +
            Number(formData.otherSales) -
            Number(formData.otherPayouts)
          }
          onChange={handleChange}
        />
      </div>
      <div className="btn-create">
        <button type="submit">Generate</button>
      </div>
    </form>
  );
};

export default GenerateForm;
