import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllExpenses,
  deleteExpenseItem,
} from "../../Redux/actions/expenses";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import CreateExpense from "./CreateExpense";
import EditExpense from "./EditExpense";
import DeleteModal from "../../Components/Modal/deleteModal";
import { SHOW_MODAL } from "../../Redux/types";
/**
 * packages
 */

export const Expenses = () => {
  const dispatch = useDispatch();
  const expenseData = useSelector((state) => state.expenses);
  const [modal, setModal] = useState(false);
  const [Id, setId] = useState();
  const { expense, loading } = expenseData;
  const expenseDetail = expense && expense ? expense : [];

  useEffect(() => {
    dispatch(getAllExpenses());
  }, [dispatch]);

  const toggle = () => {
    setModal(!modal);
  };

  const closeModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        open: false,
      },
    });
  };

  const toggleDelete = (id) => {
    setId(id);
    dispatch({
      type: SHOW_MODAL,
      payload: {
        open: true,
        title: "DELETE EXPENSE",
        component: (
          <DeleteModal
            title={"DELETE EXPENSE"}
            toggle={closeModal}
            id={id}
            actionCall={deleteItem}
          />
        ),
      },
    });
  };

  const toggleEditModal = (expense) => {
    setId(expense.id);
    dispatch({
      type: SHOW_MODAL,
      payload: {
        open: true,
        title: "EDIT EXPENSE",
        component: <EditExpense expense={expense} />,
      },
    });
  };
  function formatDate(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  }
  const deleteItem = () => {
    // dispatch(deleteExpenseItem(Id));
    dispatch(getAllExpenses());

    closeModal();
  };

  return (
    <div className="bg-black">
      <div className="homeShop logged">
        <div className="btn-create">
          <button
            onClick={() =>
              dispatch({
                type: SHOW_MODAL,
                payload: {
                  open: true,
                  title: "CREATE EXPENSE",
                  component: <CreateExpense toggle={toggle} />,
                },
              })
            }
          >
            + ADD EXPENSE
          </button>
        </div>
        <div className="account">
          <div className="page__head">
            <div
              className="page__head-item app-flex-item"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></div>
            <table
              style={{
                borderWidth: "0px",
                borderStyle: "none",
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <tr
                style={{
                  borderWidth: "0px",
                  borderBottom: "1px solid #f4f4f4",
                  width: "100%",
                  borderCollapse: "collapse",
                  background: "transparent",
                  color: "#4c4c4c",
                  textAlign: "center",
                }}
              >
                <th align="center" scope="col">
                  Date
                </th>
                <th align="center" scope="col">
                  Item
                </th>
                <th align="center" scope="col">
                  Amount
                </th>

                <th align="center" scope="col">
                  Status
                </th>
                <th align="center" scope="col">
                  Date Approved
                </th>
                <th align="center" scope="col">
                  Action
                </th>
              </tr>
              <tbody>
                {loading ? (
                  <tr style={{ textAlign: "center", width: "100%" }}>
                    <h3>LOADING.....</h3>{" "}
                  </tr>
                ) : (
                  expenseDetail &&
                  expenseDetail.map((expense, i) => {
                    const currentDate = new Date(expense?.createdAt);
                    const formattedDate = formatDate(currentDate);
                    return (
                      <tr className="dgItemStyle">
                        <td align="center"> {formattedDate}</td>
                        <td align="center">{expense?.expenseType}</td>
                        <td align="center">
                          {expense?.status === 1
                            ? expense?.approvedAmount
                            : expense?.requestedAmount}
                        </td>
                        <td align="center">{expense?.status}</td>
                        <td align="center">{formattedDate}</td>
                        <td align="center">
                          <FontAwesomeIcon
                            icon={faPen}
                            style={{
                              fontSize: "15",
                              color: "green",
                              marginRight: "1rem",
                            }}
                            className=""
                            onClick={() => toggleEditModal(expense)}
                          />
                          <span
                            style={{ fontSize: "15", color: "red" }}
                            onClick={() => toggleDelete(expense?.id)}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className=" w-[30px]"
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
