import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../../Styles/_deposit.scss";
import { bankWithdrawal } from "../../Services/apis";
import { ErrorPopUp, SuccessPopUp } from "../../Utils/toastify";
import { formatNumber } from "../../Utils/helpers";

const BankWithdrawal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputObject, setObject] = useState({
    amount: 0,
    account_number: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const updateAmount = (value) => {
    if (value === 0) {
      setObject({ ...inputObject, amount: 0 });
      return;
    }
    let currentAmount = inputObject.amount;
    if (currentAmount === "") {
      currentAmount = 0;
    }
    const newAmount = currentAmount + value;
    setObject({ ...inputObject, amount: newAmount });
  };

  const withdraw = () => {
    const payload = {
      amount: parseInt(inputObject.amount, 10),
      account_number: inputObject?.account_number,
      account_type: "nuban",
    };
    setLoading(true);
    bankWithdrawal(payload)
      .then((r) => {
        if (r.success) {
          SuccessPopUp(r.message);
          setLoading(false);
          setObject({
            amount: 0,
            account_number: '',
          })
        } else {
          ErrorPopUp(r.message);
          setLoading(false);
        }
        setLoading(false);
        // SuccessPopUp("Successfully sent request");
      })
      .catch((err) => {
        ErrorPopUp("Error occured");
        setLoading(false);
      });
  };

  return (
    <div className="deposit">
      <div className="deposit-step">
        <div className="left">
          <h3>NOTE</h3>
          <p className="my-1 text-1">
            <strong>RaimaxBet</strong> uses <strong>mGurush</strong> payment gateway to process all deposit and withdrawal transactions.
          </p>
          <p className="my-1 text-1">
            For easier and faster process verification, please ensure your wallet
            ID/phone number matches the details in your Raimaxbet account.
          </p>
          {/* <p className="my-1 text-1">
            <strong>IMPORTANT UPDATE</strong> Payouts to{" "}
            <strong>FIRST BANK </strong> accounts take longer than 48hours due
            to <strong>delays from the bank. </strong>
          </p> */}
        </div>
        <div className="right">
          <p className="pl-1">Withdrawal</p>
          
          <div>
          
            <div className="quickstake mt10">
              <div className="quickstake__item" onClick={() => updateAmount(0)}>
                {" "}
                Clear
              </div>
              <div
                className="quickstake__item"
                onClick={() => updateAmount(1000)}
              >
                {" "}
                +1000
              </div>
              <div
                className="quickstake__item"
                onClick={() => updateAmount(2000)}
              >
                {" "}
                +2000
              </div>
              <div
                className="quickstake__item"
                onClick={() => updateAmount(5000)}
              >
                {" "}
                +5000
              </div>
              <div
                className="quickstake__item"
                onClick={() => updateAmount(10000)}
              >
                {" "}
                +10000
              </div>
            </div>
            <div className=" my-1">
              <label className="">Amount:</label>
              <input
                name="amount"
                value={inputObject.amount}
                onChange={handleChange}
                type="number"
                className="deposit-input"
              />
            </div>
            
            <div className=" my-1">
              <label className="">Wallet ID/Phone Number:</label>
              <input
                name="account_number"
                type="text"
                className="deposit-input" 
                placeholder="917815252"
                value={inputObject.account_number} 
                onChange={handleChange}
              />
            </div>
            <div className=" my-1">
              <label className="">Total Withdrawal:</label>
              <input
                name="amount"
                type="text"
                disabled={true}
                className="deposit-input"
                value={formatNumber(inputObject.amount)}
              />
            </div>
          </div>

          <div className="btn-bank">
            <button onClick={withdraw} disabled={loading}>{!loading ? 'PROCEED' : 'PROCESSING...'}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankWithdrawal;
