import React from "react";

const NotFound = () => {
  return (
    <div className="not-found">
      <div>
        <h4>NOT DETAILS FOUND</h4>
      </div>
    </div>
  );
};

export default NotFound;
