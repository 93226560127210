import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_MODAL } from "../../Redux/types";
import { ChangePasswordModal } from "../../Components/Modal/ChangePasswordModal";
import { getAllUsers, getShopUsers } from "../../Services/apis";
import { Fragment } from "react";
import UserItem from "../../Components/UserItem";
import { toast } from "react-toastify";
import { getUsers } from "../../Redux/actions/user";

export default function AgencyList() {
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const { shopUsers, getUserLoading, user, error } = useSelector(
    (state) => state.auth
  );
  const fetchSubUsers = async (user, in1, in2, in3) => {
    setBusy(true);
    await getAllUsers(user.id)
      .then((res) => {
        setBusy(false);
        if (res.success) {
          const newArr = [...filtered];
          if (in3 != undefined) {
            newArr[in1].children[in2].children[in3].expanded = true;
            newArr[in1].children[in2].children[in3].children = res.data;
            setFiltered(newArr);
            return;
          } else if (in2 !== undefined) {
            newArr[in1].children[in2].expanded = true;
            newArr[in1].children[in2].children = res.data;
            setFiltered(newArr);
            return;
          } else {
            newArr[in1].expanded = true;
            newArr[in1].children = res.data;
            setFiltered(newArr);
          }
        } else {
          console.log(res.message);
        }
      })
      .catch((err) => {
        setBusy(false);
      });
  };

  // const { data, error } = useSWR("/user/account/retail/users");

  const toggle = (user, in1, in2, in3) => {
    const newArr = [...filtered];
    if (in3 !== undefined && user.expanded) {
      newArr[in1].children[in2].children[in3].expanded = false;
      setFiltered(newArr);
    } else if (in2 !== undefined && user.expanded) {
      newArr[in1].children[in2].expanded = false;
      setFiltered(newArr);
    } else if (in1 !== undefined && user.expanded) {
      newArr[in1].expanded = false;
      setFiltered(newArr);
    } else {
      fetchSubUsers(user, in1, in2, in3);
    }
  };

  useEffect(() => {
    document.body.classList = "bodyMain en-GB sport_master user Logged";

    if (data) {
      setUsers(data);
      setFiltered(data);
    }
  }, [data]);

  useEffect(() => {
    setData(shopUsers);
  }, [shopUsers]);

  const showPassword = (user) => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        open: true,
        title: "Change Password",
        component: (
          <ChangePasswordModal user_id={user.username} dispatch={dispatch} />
        ),
      },
    });
  };

  useEffect(() => {
    dispatch(getUsers(user?.id));
  }, []);
  return (
    <div id="MainContent" className="">
      <div className="Riquadro">
        <div className="TopSX">
          <div className="TopDX">
            <h3>Cashier List</h3>
          </div>
        </div>
        <div className="CntSX">
          {data && (
            <div className="CntDX">
              <div className="betslist">
                <div className="RiquadroSrc">
                  <div className="Cnt">
                    <div>
                      <div className="pb15 pt15">
                        <table className="SearchContainerStyle">
                          <tbody>
                            <tr className="SearchSectionStyle">
                              <td className="SearchDescStyle">
                                Filter By
                                <select
                                  name="ac$w$PC$PC$BetList$ddlFiltoData"
                                  className="dropdownFiltoData"
                                  style={{ width: "150px" }}
                                  onChange={(e) => {
                                    const val = e.target.value;
                                    if (val === "") {
                                      setFiltered(users);
                                    } else {
                                      setFiltered(
                                        users.filter(
                                          (user) =>
                                            user.rolename === e.target.value
                                        )
                                      );
                                    }
                                  }}
                                >
                                  <option value="">All</option>
                                  {(user.role === "Super Agent" ||
                                    user.role === "Master Agent") && (
                                    <option value="Agent">Agent</option>
                                  )}
                                  {(user.role === "Super Agent" ||
                                    user.role === "Master Agent" ||
                                    user.role === "Agent") && (
                                    <option value="Shop">Shop</option>
                                  )}
                                  <option value="Cashier">Cashier</option>
                                  <option value="Player">Player</option>
                                </select>
                              </td>
                              <td className="SearchControlsStyle">
                                <td className="SearchDescStyle">
                                  Fast Search
                                  <input
                                    name="ac$w$PC$PC$BetList$txtCodiceCoupon"
                                    type="text"
                                    defaultValue=""
                                    className="textbox dropdownFiltoData"
                                    style={{ width: "150px" }}
                                    onKeyUp={(e) => {
                                      const q = e.target.value;

                                      if (q.length >= 3) {
                                        setFiltered(
                                          users.filter((user) =>
                                            user.username.includes(q)
                                          )
                                        );
                                      }
                                      if (q === "") setFiltered(users);
                                    }}
                                  />
                                </td>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="divDg">
                        <div className="">
                          <table
                            className="dgStyle expandable"
                            cellSpacing="0"
                            border="0"
                          >
                            <tbody>
                              <tr className="dgHdrStyle">
                                <th width="10%">Id</th>
                                <th>User Type</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Balance</th>
                                <th>Action</th>
                              </tr>
                            </tbody>
                            <tbody>
                              {filtered &&
                                // !error &&
                                filtered.map((firsthChild, i) => (
                                  <Fragment key={i}>
                                    <UserItem
                                      style="first"
                                      data={firsthChild}
                                      toggle={(item) => toggle(item, i)}
                                      changePassword={showPassword}
                                      auth={user}
                                    />

                                    {busy && (
                                      <tr colSpan="6">
                                        <td align="center">Loading...</td>
                                      </tr>
                                    )}
                                    {/* {firsthChild.expanded && (
                                      <tr>
                                        <td colSpan={6}>
                                          <table
                                            className="dgStyle expandable"
                                            cellSpacing="0"
                                            border="0"
                                          >
                                            {firsthChild.children.map(
                                              (secondChild, c) => (
                                                <Fragment
                                                  key={`${c}-${secondChild.id}-second`}
                                                >
                                                  <UserItem
                                                    style="second"
                                                    data={secondChild}
                                                    toggle={(item) =>
                                                      toggle(item, i, c)
                                                    }
                                                    changePassword={
                                                      showPassword
                                                    }
                                                  />
                                                  {busy && (
                                                    <tr colSpan="6">
                                                      <td align="center">
                                                        Loading...
                                                      </td>
                                                    </tr>
                                                  )}
                                                      {secondChild.expanded && (
                                                        <tr>
                                                          <td colSpan={6}>
                                                            <table
                                                              className="dgStyle expandable"
                                                              cellSpacing="0"
                                                              border="0"
                                                            >
                                                              {secondChild.children.map(
                                                                (
                                                                  thirdChild,
                                                                  ct
                                                                ) => (
                                                                  <Fragment>
                                                                    <UserItem
                                                                      style="third"
                                                                      data={
                                                                        thirdChild
                                                                      }
                                                                      toggle={(
                                                                        item
                                                                      ) =>
                                                                        toggle(
                                                                          item,
                                                                          i,
                                                                          c,
                                                                          ct
                                                                        )
                                                                      }
                                                                      changePassword={
                                                                        showPassword
                                                                      }
                                                                    />
                                                                    {busy && (
                                                                      <tr colSpan="6">
                                                                        <td align="center">
                                                                          Loading...
                                                                        </td>
                                                                      </tr>
                                                                    )}
                                                                    {thirdChild.expanded && (
                                                                      <tr>
                                                                        <td
                                                                          colSpan={
                                                                            6
                                                                          }
                                                                        >
                                                                          <table
                                                                            className="dgStyle expandable"
                                                                            cellSpacing="0"
                                                                            border="0"
                                                                          >
                                                                            {thirdChild.children.map(
                                                                              (
                                                                                fourthChild,
                                                                                ct
                                                                              ) => (
                                                                                <UserItem
                                                                                  style="fourth"
                                                                                  data={
                                                                                    fourthChild
                                                                                  }
                                                                                  toggle={(
                                                                                    item
                                                                                  ) =>
                                                                                    toggle(
                                                                                      item,
                                                                                      i,
                                                                                      c,
                                                                                      ct
                                                                                    )
                                                                                  }
                                                                                  changePassword={
                                                                                    showPassword
                                                                                  }
                                                                                />
                                                                              )
                                                                            )}
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    )}
                                                                  </Fragment>
                                                                )
                                                              )}
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      )}
                                                </Fragment>
                                              )
                                            )}
                                          </table>
                                        </td>
                                      </tr>
                                    )} */}
                                  </Fragment>
                                ))}
                              {filtered && filtered.length === 0 && (
                                <tr className="dgItemStyle">
                                  <td colSpan="8" className="txt-c">
                                    No result found
                                  </td>
                                </tr>
                              )}
                              {/* {!filtered.length && (
                                <tr className="dgItemStyle">
                                  <td colSpan="8" className="txt-c">
                                    Loading...
                                  </td>
                                </tr>
                              )} */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
