import React from "react";
import moment from "moment";

const LiveScoreCard = ({ type, liveItem }) => {
  const formatDate = (str, format = "HH:mm") => moment(str).format(format);

  return (
    <div className="livescore-item ">
      <div className="bg-blue livescore-flex livescore-header p-1">
        <small>
          <strong>{liveItem?.LeagueName}ee</strong> /
        </small>
        <small className="ml-1 ">{liveItem?.season}ee</small>
      </div>
      {liveItem?.events.map((item) => (
        <div className="flex livescore-item-detail">
          <div className="livescore-item-detail-time">
            <p className="ml-1">{formatDate(item?.start_at)}</p>
            {type === "All" ? (
              <span className="livescore-status">{item?.status}</span>
            ) : (
              <span className="livescore-stage">{item?.status}</span>
            )}
          </div>
          <div className=" livescore-item-detail-teams">
            <p>{item?.home_team?.name}</p>
            <p className="-mt-1">{item?.away_team?.name}</p>
          </div>
          <div className="livescore-item-detail-outcome">
            <p className="ml-1  text-green">{item?.home_score?.current}</p>
            <p className="ml-1 text-green">{item?.away_score?.current}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LiveScoreCard;
