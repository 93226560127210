import React, { useState } from "react";
import Bet from "../../Components/Bet";

const X2 = () => {
  return (
    <div className="">
      <Bet />
    </div>
  );
};

export default X2;
