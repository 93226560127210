import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { CashIn } from "../Account/CashIn";
import { CashOut } from "../Account/CashOut";
import { Expenses } from "../Account/Expenses";
import { LastApproved } from "../Account/LastApproved";
import { getSalesReport } from "../../Services/apis";
import Confirm from "../../Components/Modal/Confirm";

const CashBook = () => {
  const [activeTab, setActive] = useState(1);
  const [status, setStatus] = useState(0);
  const [activeTabItem, setActiveItem] = useState(9);
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    date: "",
    openingBalance: 0,
    closingBalance: 0,
    cashin: 0,
    cashout: 0,
    expenses: 0,
    normalPayouts: 0,
    normalSales: 0,
    onlinePayouts: 0,
    onlineSales: 0,
    otherPayouts: 0,
    otherSales: 0,
    status: 0,
  });

  // const generateData = async () => {
  //   setLoading(true);

  //   const payload = {
  //     agent_id: user.id,
  //   };
  //   await generateLatestApproved(payload)
  //     .then((r) => {
  //       setLoading(false);
  //       setDetails(r?.data);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };
  useEffect(() => {
    fetchBranchLastApproved();
  }, [activeTabItem]);

  const fetchBranchLastApproved = async () => {
    setLoading(true);

    const report = await getSalesReport(status);
    if (report.success) {
      setLoading(false);
      setDetails(report.data);
    }
  };

  useEffect(() => {
    document.body.classList = "bodyMain en-GB sport_master user Logged";
  }, []);

  return (
    <div className="">
      <div className="Riquadro">
        <div className="CntSX">
          <div className="CntDX">
            <div id="s_w_PC_PC_panelSquare">
              <div className="RiquadroNews Reg">
                <div className="Cnt">
                  <h4
                    className="p-4 commission-head"
                    style={{
                      margin: "1rem 0",
                      background: "white",
                      color: "black",
                    }}
                  >
                    Ticket barcode
                  </h4>
                </div>
                <div className="cashbook">
                  <ul className="t-header">
                    <li
                      style={{ marginLeft: "-2.5rem" }}
                      onClick={() => setActive(1)}
                      className={
                        activeTab === 1 ? `active tab-item ml` : "tab-item ml"
                      }
                    >
                      Last Approved
                    </li>
                    <li
                      onClick={() => setActive(2)}
                      className={
                        activeTab === 2 ? `active tab-item` : "tab-item"
                      }
                    >
                      Cash In
                    </li>
                    <li
                      onClick={() => setActive(3)}
                      className={
                        activeTab === 3 ? `active tab-item` : "tab-item"
                      }
                    >
                      Cash Out
                    </li>
                    <li
                      onClick={() => setActive(4)}
                      className={
                        activeTab === 4 ? `active tab-item` : "tab-item"
                      }
                    >
                      Expenses
                    </li>
                  </ul>
                  <div className="tab-content">
                    {activeTab === 1 ? <LastApproved /> : ""}
                    {activeTab === 2 ? <CashIn /> : ""}
                    {activeTab === 3 ? <CashOut /> : ""}
                    {activeTab === 4 ? <Expenses /> : ""}
                  </div>

                  <div className="table-bottom">
                    <ul className="t-header" style={{ color: "black" }}>
                      <li
                        style={{ marginLeft: "-2.5rem", marginRight: "2rem" }}
                        className={
                          activeTabItem === 9 ? `active tab-item` : "tab-item"
                        }
                        onClick={() => {
                          setActiveItem(9);
                          setStatus(0);
                        }}
                      >
                        Pending
                      </li>
                      <li
                        className={
                          activeTabItem === 10 ? `active tab-item` : "tab-item"
                        }
                        onClick={() => {
                          setStatus(1);
                          setActiveItem(10);
                        }}
                      >
                        Approved
                      </li>
                    </ul>
                    <table
                      style={{
                        borderWidth: "0px",
                        borderStyle: "none",
                        width: "100%",
                        // borderCollapse: "collapse",
                      }}
                      className="table-home"
                    >
                      <tr
                        style={{
                          borderWidth: "0px",
                          borderBottom: "1px solid #f4f4f4",
                          borderTop: "1px solid #f4f4f4",

                          width: "100%",
                          // borderCollapse: "collapse",
                          background: "transparent",
                          color: "#4c4c4c",
                          textAlign: "center",
                        }}
                        className=""
                      >
                        <th align="center">Date</th>
                        <th align="center">Opening</th>
                        <th align="center" scope="col">
                          Normal Sales
                        </th>

                        <th align="center" scope="col">
                          Normal Payout
                        </th>
                        <th align="center" scope="col">
                          Online Sales
                        </th>
                        <th align="center" scope="col">
                          Online Payout
                        </th>

                        <th align="center" scope="col">
                          Cash In
                        </th>
                        <th align="center" scope="col">
                          Cash Out
                        </th>
                        <th align="center" scope="col">
                          Expenses
                        </th>
                        <th align="center" scope="col">
                          Closing
                        </th>
                      </tr>
                      <tbody>
                        {details &&
                          details?.map((item, i) => (
                            <tr className="dgItemStyle" key={i}>
                              <td align="center">
                                {" "}
                                {moment(item?.date).format("DD/MM/YYYY")}
                              </td>
                              <td align="center"> {item?.openingBalance}</td>
                              <td align="center"> {item?.normalSales}</td>
                              <td align="center">{item?.normalPayouts}</td>
                              <td align="center">{item?.onlineSales}</td>
                              <td align="center">{item?.onlinePayouts}</td>
                              <td align="center"> {item?.cashin}</td>
                              <td align="center"> {item?.cashout}</td>
                              <td align="center"> {item?.expenses}</td>
                              <td align="center"> {item?.closingBalance}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashBook;
