import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SHOW_MODAL } from "../Redux/types";
import ElbetFunds from "../pages/TransferElbet";
import { useDispatch } from "react-redux";
import { CreatePin } from "./Modal/CreatePin";

export const AccountMenu = ({ componentClase, user }) => {
  const [isCancel, setCancel] = useState({ show: false, ticketId: "" });
  const dispatch = useDispatch();
  const closeModal = () => {
    setCancel({ ...isCancel, show: false });
  };
  return (
    <div className={componentClase}>
      <ul>
        <li>
          <a title="My Account" href="#">
            My Account
          </a>
          <ul>
            {user?.role === "Shop" && (
              <li>
                <NavLink title="Dashboard" to="/Account/Dashboard">
                  Dashboard
                </NavLink>
              </li>
            )}
            <li>
              <NavLink title="Bet List" to="/Account/BetList">
                Bet List
              </NavLink>
            </li>
            <li>
              <NavLink title="Bet List" to="/Account/BetListPayout">
                Bet List Payout
              </NavLink>
            </li>

            {/* <li>
              <NavLink title="Financial Report" to="/Account/FinancialReport">
                Financial Report
              </NavLink>
            </li> */}

            <li>
              <NavLink title="Coupon Bet List" to="/Account/CouponBetList">
                Coupon Bet List
              </NavLink>
            </li>
            <li>
              <NavLink title="Transactions List" to="/Account/TransactionList">
                Transactions List
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                title="User Transactions List"
                to="/Account/TransactionList"
              >
                User Transactions List
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                title="Bonus Transactions List"
                to="/Account/BonusTransactionList"
              >
                Bonus Transactions List
              </NavLink>
            </li> */}

            {user?.role !== "Cashier" && (
              <>
                <li>
                  <NavLink title="Deposit" to="/Account/Deposit">
                    Deposit
                  </NavLink>
                </li>
                <li>
                  <NavLink title="Withdraw" to="/Account/Withdrawal">
                    Withdraw
                  </NavLink>
                </li>
              </>
            )}
            {user?.role === "Shop" && (
              <>
                <li>
                  <NavLink title="Cash In" to="/Account/Cash-Book">
                    Cash Book
                  </NavLink>
                </li>
                <li>
                  <NavLink title="1X2" to="/Account/1X2">
                    1X2
                  </NavLink>
                </li>
                <li>
                  <NavLink title="Commission" to="/Account/Commission">
                    Commission
                  </NavLink>
                </li>
              </>
            )}
            {user?.role === "Shop" && (
              <>
                <li className="uxr-li-link">
                  <NavLink to="/Account/OnlineSales">Online Sales</NavLink>
                </li>
                <li>
                  <NavLink title="Commission" to="/Account/Bonus">
                    Bonus
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </li>
        <li>
          <ul>
            {user?.role === "Shop" ? (
              <>
                <a title="Change Password" href="#">
                  PDR
                </a>
                <li className="uxr-li-link">
                  <NavLink to="/Account/NewUser?usertype=cashier">
                    New Cashier
                  </NavLink>
                </li>
              </>
            ) : (
              ""
              // <li className="uxr-li-link">
              //   <NavLink to="/Account/NewUser?usertype=player">
              //     New User
              //   </NavLink>
              // </li>
            )}
            {(user?.role === "Master Agent" ||
              user?.role === "Super Agent" ||
              user?.role === "Agent") && (
              <li className="uxr-li-link">
                <NavLink to="/Account/NewUser?usertype=shop">New Shop</NavLink>
              </li>
            )}
            {(user?.role === "Master Agent" ||
              user?.role === "Super Agent") && (
              <li className="uxr-li-link">
                <NavLink to="/Account/NewUser?usertype=agent">
                  New Agent
                </NavLink>
              </li>
            )}
            {/* <li className="uxr-li-link">
              <a href="https://globalbet.virtual-horizon.com/engine/backoffice/login_BU.htm#accounts"
                  target="_blank" className="level-2">
                  Virtual Credit
              </a>
            </li> */}
            {user?.role !== "Cashier" && (
              <li className="uxr-li-link">
                <NavLink to="/Account/AgencyList">Cashier List</NavLink>
              </li>
            )}

            {user?.role === "Shop" && (
              <>
                <li
                  className="uxr-li-link"
                  onClick={() =>
                    dispatch({
                      type: SHOW_MODAL,
                      payload: {
                        open: true,
                        title: "TRANSFER TO ELBET ACCOUNT",
                        component: (
                          <ElbetFunds
                            toggle={closeModal}
                            typeOfTrans="transfer"
                          />
                        ),
                      },
                    })
                  }
                >
                  <NavLink to="#">Transfer Funds (Elbet)</NavLink>
                </li>
                <li
                  className="uxr-li-link"
                  onClick={() =>
                    dispatch({
                      type: SHOW_MODAL,
                      payload: {
                        open: true,
                        title: "Withdraw Fund From ELBET Account",
                        component: (
                          <ElbetFunds
                            toggle={closeModal}
                            typeOfTrans="withdraw"
                          />
                        ),
                      },
                    })
                  }
                >
                  <NavLink to="#">Withdraw Funds (Elbet)</NavLink>
                </li>
                <li className="uxr-li-link">
                  <NavLink to="/Account/Transfer/internal">
                    {user?.role === "Cashier"
                      ? "Transfer Funds Online"
                      : "Transfer Funds to Cashier"}
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </li>
        <li>
          <a title="Account Detail" href="#">
            Account Detail
          </a>
          <ul>
            <li
              className="uxr-li-link"
              onClick={() =>
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    open: true,
                    title: "Create Pin",
                    component: (
                      <CreatePin toggle={closeModal} typeOfTrans="transfer" />
                    ),
                  },
                })
              }
            >
              <NavLink title="Create Pin" to="#">
                Create Pin
              </NavLink>
            </li>
            <li>
              <NavLink title="Change Password" to="/Account/ChangePassword">
                Change Password
              </NavLink>
            </li>
            <li>
              <NavLink title="Change Password" to="/Account/PersonalDetails">
                Personal Data
              </NavLink>
            </li>
            <li>
              <NavLink title="Access Logs" to="/Account/LoginHistory">
                View Session
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
