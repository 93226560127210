import React, { useEffect, useState } from "react";
import { getLiveFixtures } from "../../Services/apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import {
  getLiveOdds,
  getSpread,
  groupLiveFixturesBySports,
  groupLiveSports,
  slugify,
} from "../../Utils/helpers";
import { LiveEventsOverview, matchStatus } from "../../Utils/constants";
import { LiveOdd } from "../../Components/LiveOdd";
import { useSelector } from "react-redux";
import "../../Static/scss/_live.scss";

export function LiveBetting({ history }) {
  const [availableSports, setAvailableSports] = useState([]);
  const [sports, setSports] = useState([]);
  const [filteredSports, setFilteredSports] = useState([]);
  const [activeSport, setActiveSport] = useState("all");
  const coupon = useSelector(({ couponData }) => couponData.coupon);
  const { SportsbookGlobalVariable, SportsbookBonusList } = useSelector(
    (state) => state.sportsBook
  );

  const getData = () => {
    getLiveFixtures().then((response) => {
      if (response.fixtures) {
        let tournaments = groupLiveSports(response.fixtures);
        let sports = groupLiveFixturesBySports(response?.fixtures);

        setAvailableSports(sports);

        sports.forEach((item, key) => {
          item.Tournaments = [];
          item.headers = LiveEventsOverview.find(
            (sport) => sport.id == item.Id
          );
          tournaments.forEach((tournament) => {
            if (tournament.sport_id == item.Id)
              item.Tournaments.push(tournament);
          });
        });
        setSports(sports);
      } else {
        console.log("no fixtures returned");
      }
    });
  };

  useEffect(() => {
    getLiveFixtures().then((response) => {
      // setAvailableSports(response.data.sports || []);
      if (response.fixtures) {
        let tournaments = groupLiveSports(response.fixtures);
        let sports = groupLiveFixturesBySports(response?.fixtures);

        sports.forEach((item, key) => {
          item.Tournaments = [];
          item.headers = LiveEventsOverview.find(
            (sport) => sport.id === item.sport_id
          );
          tournaments.forEach((tournament) => {
            if (tournament.sport_id === item.Id)
              item.Tournaments.push(tournament);
          });
        });
        setSports(sports);
      } else {
        console.log("no fixtures returned");
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        getData();
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData(); // Initial fetch

    const interval = setInterval(fetchData, 30000); // Adjust interval as needed

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    filterSports(activeSport);
  }, [sports]);

  const togglePanel = (e) => {
    const parent = e.currentTarget.parentElement;
    parent.classList.toggle("closed");
  };

  const filterSports = (sport) => {
    if (sport === "all") {
      setFilteredSports(sports);
      setActiveSport("all");
    } else {
      const filter = sports.filter((el) => el.Name === sport);
      if (filter) {
        setFilteredSports(filter);
        setActiveSport(sport);
      }
    }
  };

  return (
    <>
      <div className="topHeader panel-heading">
        <div className="sport-menu">
          <div
            onClick={() => filterSports("all")}
            className={`menu all ${activeSport === "all" ? "selected" : ""}`}
          >
            <FontAwesomeIcon icon={faTrophy} />
            <span>All</span>
          </div>
          {availableSports.length > 0 &&
            availableSports.map((sport) => (
              <div
                onClick={() => filterSports(sport.Name)}
                className={`menu sport_${sport.Id} ${
                  activeSport === sport.Name ? "selected" : ""
                }`}
                key={sport.Id}
              >
                <span className={`live-item-icon icon_${sport.Id}`} />
                <span>{sport.Name}</span>
                <span className="sport-count">{sport.EventCount}</span>
              </div>
            ))}
        </div>
      </div>
      <div id="live-grouped-odds">
        {filteredSports?.length !== 0 && (
          <div id="CNWrapper">
            <div className="wrapper">
              <div className="content" id="divMainContentLive">
                <div id="divPanelInPlayEvents">
                  <div id="divMainEventsLive">
                    {filteredSports.map((sport) => (
                      <div className="tipoSport" key={sport.Id}>
                        <div
                          onClick={togglePanel}
                          className="sport"
                          style={{
                            backgroundImage: `url(/img/sports/live/${slugify(
                              sport.Name
                            )}.png)`,
                          }}
                        >
                          <span className="arrow" />
                          <div className="count">
                            ({sport?.Tournaments?.length})
                          </div>
                          {sport.Name}
                        </div>
                        {sport.Tournaments.map((tournament) => (
                          <div className="groups" key={tournament.Id}>
                            <div className="group">
                              <div className="titleGroup" onClick={togglePanel}>
                                {tournament.category + " - " + tournament.Name}
                                <span className="arrow" />
                                <div className="count">
                                  ({tournament.Events.length})
                                </div>
                              </div>
                              <div className="events">
                                {tournament.Events.map((match) => (
                                  <div className="item" key={match.Id}>
                                    <a className="codPub">
                                      {match.activeMarkets}{" "}
                                    </a>
                                    <div className="evento">
                                      <span
                                        onClick={() =>
                                          history.push(
                                            `/Live/LiveEventDetail?EventID=${match.matchID}`
                                          )
                                        }
                                      >
                                        {match.name}
                                      </span>
                                    </div>
                                    <div className="time">
                                      <span className="min">
                                        {match.eventTime} min{" "}
                                      </span>

                                      <span className="fase">
                                        {matchStatus(match.matchStatus)}
                                      </span>
                                    </div>
                                    <div className="risultato over">
                                      <span className="c1">
                                        {match.homeScore}
                                      </span>
                                      &nbsp;-&nbsp;
                                      <span className="c2">
                                        {match.awayScore}
                                      </span>
                                    </div>
                                    <div className="pnlQuote">
                                      <div className="container">
                                        <div className="quote">
                                          {sport.headers &&
                                            sport.headers.markets.map(
                                              (market) => (
                                                <div
                                                  className={`mainSE o${market.outcomes.length}`}
                                                  key={`${slugify(
                                                    sport.Name
                                                  )}-${market.id}`}
                                                >
                                                  <div
                                                    className="SE"
                                                    style={{
                                                      color: "white",
                                                      marginTop: ".5rem",
                                                    }}
                                                  >
                                                    {market.name}
                                                  </div>
                                                  <div
                                                    className={
                                                      market.hasSpread
                                                        ? "hndItem"
                                                        : ""
                                                    }
                                                  >
                                                    {market.hasSpread &&
                                                      match.outcomes &&
                                                      getSpread(
                                                        match.outcomes,
                                                        market
                                                      ) !== undefined && (
                                                        <div className="hnd">
                                                          <div className="hndTitle">
                                                            hnd
                                                          </div>
                                                          <div className="hndValue">
                                                            {getSpread(
                                                              match.outcomes,
                                                              market
                                                            )}
                                                          </div>
                                                        </div>
                                                      )}
                                                    {market.outcomes.map(
                                                      (outcome) => (
                                                        <div
                                                          className={`OddsQuotaItemStyleTQ 
                                                                ${
                                                                  market.hasSpread
                                                                    ? "hndItem"
                                                                    : ""
                                                                } 
                                                                ${
                                                                  getSpread(
                                                                    match.outcomes,
                                                                    market
                                                                  ) ===
                                                                  undefined
                                                                    ? "noOdd"
                                                                    : ""
                                                                }
                                                        g1`}
                                                          key={`${slugify(
                                                            sport.Name
                                                          )}-${market.id}-${
                                                            outcome.id
                                                          }`}
                                                        >
                                                          <LiveOdd
                                                            newOdds={getLiveOdds(
                                                              match.outcomes,
                                                              market,
                                                              outcome
                                                            )}
                                                            outcome={outcome}
                                                            market={market}
                                                            fixture={match}
                                                            coupon={coupon}
                                                            globalVars={
                                                              SportsbookGlobalVariable
                                                            }
                                                            bonusList={
                                                              SportsbookBonusList
                                                            }
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
