import React from "react";
import { SHOW_MODAL } from "../../Redux/types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const CashInOutVerifyModal = () => {
  const router = useHistory();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    <div className="expense-form">
      <div className="expense-input">
        {/* <label>Proceed to Cashbook to Handle Report</label> */}
        <label className="" style={{ textTransform: "capitalize" }}>
          You have pending cashin/cashout in your cashbook, please approve them
          first
        </label>
        <button
          className="green"
          onClick={() => {
            router.push("/Account/Cash-Book");
            dispatch({ type: SHOW_MODAL, payload: null });
            window.location.reload();
          }}
          style={{
            background: "#000229",
            color: "white",
            marginRight: "1rem",
            border: "0px",
            padding: ".3rem 1rem",
          }}
        >
          Proceed to Cashbook
        </button>
      </div>
    </div>
  );
};

export default CashInOutVerifyModal;
