import React from "react";

/**
 * packages
 */

export const BonusMathsCard = ({
  propsClass,
  title,
  firstValue,
  secondValue,
  symbol,
  total,
  firstLabel,
  secondLabel,
  thirdLabel,
  thirdValue,
  fourthLabel,
}) => {
  console.log(symbol);
  return (
    <div className={propsClass ? `"math-card" ${propsClass}` : "math-card"}>
      <div className="math-card-header">
        <h4>{title}</h4>
      </div>
      <div className="card-row">
        <div className="card-input">
          <label>{firstLabel}</label>
          <input value={firstValue} type="number" disabled />
        </div>
        <p className="bonus-card-symbol">{symbol}</p>
        <div className="card-input">
          <label>{secondLabel}</label>
          <input value={secondValue} type="number" disabled />
        </div>
        {propsClass === "full" && <p className="bonus-card-symbol">-</p>}

        {propsClass === "full" && (
          <div className="card-input">
            <label>{fourthLabel}</label>
            <input name={thirdValue} type="text" disabled />
          </div>
        )}
        <p className="bonus-card-symbol">=</p>

        <div className="card-input">
          <label>{thirdLabel}</label>
          <input value={total} type="text" disabled />
        </div>
      </div>
    </div>
  );
};
