import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExpensesType } from "../../Services/apis";
import {
  createExpense,
  updateExpense,
  getExpenseById,
  getAllExpenses,
} from "../../Redux/actions/expenses";
import { toast } from "react-toastify";
import { SHOW_MODAL } from "../../Redux/types";

const EditExpense = ({ toggle, item, expense }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const expenseData = useSelector((state) => state.expenses);
  const { oneExpense, loading } = expenseData;
  const expenseDetail = oneExpense && oneExpense ? oneExpense : [];

  const { user } = useSelector((state) => state.auth);
  const [inputObject, setObject] = useState({
    date: "",
    expenseTypeId: expense.expenseTypeId,
    expense: expense?.id,
    expenseType: expense?.expenseType,
    branch_id: "",
    user_id: "",
    amount: expense?.requestedAmount,
    comment: expense?.branchComment,
  });

  useEffect(() => {
    // dispatch(getExpenseById(expense?.id));
  }, [dispatch]);

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };
  const creatExpense = async (e) => {
    e.preventDefault();
    const payload = {
      date: new Date(),
      expenseTypeId: inputObject.expenseTypeId,
      id: expense?.id,
      amount: inputObject.amount,
      comment: inputObject.comment,
    };

    await dispatch(updateExpense(payload));
    await dispatch(getAllExpenses());
    await dispatch({
      type: SHOW_MODAL,
      payload: {
        open: false,
      },
    });
  };

  useEffect(() => {
    fetchExpenseType();
  }, [dispatch]);

  const fetchExpenseType = async () => {
    setLoading(true);
    const expenseTypes = await getExpensesType();
    if (expenseTypes.success) {
      setDetails(expenseTypes.data);
    }
    if (!expenseTypes.success) {
      toast.error("Internal server eror");
    }
    setLoading(false);
  };

  return (
    <form className="expense-form">
      <div className="expense-input">
        <label>EXPENSE</label>
        <select onChange={handleChange} name="expenseTypeId">
          <option value={inputObject.expenseTypeId}>
            {inputObject.expenseType}
          </option>
          {isLoading
            ? "Loading..."
            : details?.map((type) => (
                <option
                  defaultValue={inputObject.expenseType}
                  value={type?.id}
                  key={type?.id}
                >
                  {type?.title}
                </option>
              ))}
        </select>
      </div>
      <div className="expense-input">
        <label>AMOUNT</label>
        <input
          type="number"
          onChange={handleChange}
          value={Number(inputObject.amount)}
          name="amount"
        />
      </div>
      <div className="expense-input">
        <label>COMMENT</label>
        <input
          type="text"
          onChange={handleChange}
          value={inputObject.comment}
          name="comment"
        />
      </div>
      <div className="expense-btn">
        <input
          type="button"
          name="expenses"
          value="SAVE"
          className="btn-green"
          onClick={(e) => creatExpense(e)}
        />
        <input
          type="button"
          name="expenses"
          value="CANCEL"
          className=" btn-red"
          onClick={() =>
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: false,
              },
            })
          }
        />
      </div>
    </form>
  );
};

export default EditExpense;
