import React, { useState } from "react";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { getSettledCommision } from "../../Services/apis";
import NotFound from "../../Components/NotFound";
import { formatDate } from "../../Utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
} from "@fortawesome/free-solid-svg-icons";
export default function Commission({ history, location }) {
  const urlParam = new URLSearchParams(location.search);
  const usertype = urlParam.get("usertype");
  const [activeTab, setActive] = useState("all");
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [active, setActiveIndex] = useState();
  const [show, setShow] = useState(false);
  const [inputObject, setObject] = useState({ startDate: "", endDate: "" });

  const fetchCommissions = () => {
    setLoading(true);
    const dates = {
      from: formatDate(inputObject?.startDate, "DD-MM-YYYY"),
      to: formatDate(inputObject?.endDate, "DD-MM-YYYY"),
      type: activeTab === "all" ? " " : activeTab,
    };
    getSettledCommision(dates)
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const showItem = (i) => {
    setActiveIndex(i);
    setShow(!show);
  };

  return (
    <div id="MainContent" className="">
      <div className="Riquadro">
        <div className="CntSX">
          <div className="CntDX">
            <div id="s_w_PC_PC_panelSquare">
              <div className="RiquadroNews Reg">
                <div className="Cnt">
                  <div>
                    <div id="s_w_PC_PC_upDati">
                      <h4 className="p-4 commission-head">Commission</h4>
                      <div className="cashbook">
                        <ul className="t-header ">
                          <li
                            onClick={() => setActive("all")}
                            className={
                              activeTab === "all"
                                ? `active tab-item ml`
                                : "tab-item ml"
                            }
                          >
                            All
                          </li>
                          <li
                            onClick={() => setActive("sports")}
                            className={
                              activeTab === "sports"
                                ? `active tab-item`
                                : "tab-item"
                            }
                          >
                            Sports
                          </li>
                          <li
                            onClick={() => setActive("casino")}
                            className={
                              activeTab === "casino"
                                ? `active tab-item`
                                : "tab-item"
                            }
                          >
                            Casino
                          </li>
                          <li
                            onClick={() => setActive("virtual")}
                            className={
                              activeTab === "virtual"
                                ? `active tab-item `
                                : "tab-item "
                            }
                          >
                            Virtual
                          </li>
                        </ul>
                      </div>
                      <div className="content">
                        <div className="form-row">
                          <div className="form-date">
                            <div>
                              <label>Date From</label>{" "}
                              <input
                                type="date"
                                name="startDate"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="form-date">
                            <div>
                              <label className="label">Date To</label>{" "}
                              <input
                                type="date"
                                name="endDate"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="form-date">
                            <button onClick={fetchCommissions}>
                              {loading ? "Loading..." : "Proceed"}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div className="content">
                          {loading && (
                            <div>
                              <h4 className="loader">Loading...</h4>
                            </div>
                          )}
                          {data && (
                            <table>
                              <tr>
                                <th>Channel Name</th>
                                <th>Settled Date</th>
                                <th>Coupon Count</th>
                                <th>No of Selections</th>
                                <th>Stake</th>
                                <th>Commission Band</th>
                                <th>Commission Estimate</th>
                              </tr>
                              {data ? (
                                data?.map((item, i) => (
                                  <>
                                    <tr
                                      onClick={() => showItem(i)}
                                      className="main"
                                    >
                                      <td
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faArrowAltCircleDown}
                                          className=" arrow-icon"
                                        />{" "}
                                        {item?.channel}
                                      </td>
                                      <td>
                                        {formatDate(
                                          item?.start_date,
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td>{item?.bet_info?.length}</td>
                                      <td>-</td>
                                      <td>{item?.total_sales}</td>
                                      <td>{item?.commission_band}%</td>
                                      <td>{item?.commission}</td>
                                    </tr>
                                    {active === i && show
                                      ? item?.bet_info.map((bet) => (
                                          <tr className="nest-row">
                                            <td
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {bet?.type}
                                            </td>
                                            <td>
                                              {formatDate(
                                                bet?.start_date,
                                                "DD-MM-YYYY"
                                              )}
                                            </td>
                                            <td>{bet?.total_tickets}</td>
                                            <td>{bet?.selections_count}</td>
                                            <td>{bet?.stake}</td>
                                            <td>{bet?.commission_band}%</td>
                                            <td>{bet?.commission}</td>
                                          </tr>
                                        ))
                                      : ""}
                                  </>
                                ))
                              ) : (
                                <div>
                                  <NotFound />
                                </div>
                              )}
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="BtmSX">
                  <div className="BtmDX"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="BtmSX">
          <div className="BtmDX"></div>
        </div>
      </div>
    </div>
  );
}
