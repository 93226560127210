import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SHOW_MODAL, UPDATE_USER_BALANCE } from "../../Redux/types";
import { formatNumber } from "../../Utils/helpers";
import { useHistory } from "react-router-dom";

const VerifyTransaction = ({ title, toggle }) => {
  const router = useHistory();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <div className="expense-form">
      <div className="expense-input">
        {/* <label>Proceed to Cashbook to Handle Report</label> */}
        <label className="" style={{ textTransform: "capitalize" }}>
          {title}
        </label>
        <button
          className="green"
          onClick={() => {
            router.push("/Account/Cash-Book");
            dispatch({ type: SHOW_MODAL, payload: null });
            window.location.reload();
          }}
          style={{
            background: "#000229",
            color: "white",
            marginRight: "1rem",
            border: "0px",
            padding: ".3rem 1rem",
          }}
        >
          Proceed to Cashbook
        </button>
      </div>
    </div>
  );
};

export default VerifyTransaction;
