import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LOADING, SHOW_MODAL } from "../Redux/types";
import { MdOutlineClose } from "react-icons/md";
import { printTicket } from "../Utils/couponHelpers";
import { toast } from "react-toastify";
import { getActiveGames } from "../Services/apis";
import { formatDate } from "../Utils/helpers";
import GameAddedModal from "./Modal/GameAdded";

const data = [
  { id: 1, market: "FT 1X2: 1", marketCode: "1" },
  { id: 2, market: "FT 1X2: 2", marketCode: "2" },
  { id: 3, market: "FT 1X2: X", marketCode: "0" },
  { id: 4, market: "FT 1X2/BTS: 1 and No", marketCode: "1/+-" },
  { id: 5, market: "FT 1X2/BTS: 1 and Yes", marketCode: "1/++" },
  { id: 6, market: "FT 1X2/BTS: 2 and No", marketCode: "2/+-" },
  { id: 7, market: "FT 1X2/BTS: 2 and Yes", marketCode: "2/++" },
  { id: 8, market: "FT 1X2/BTS: X and No", marketCode: "X/+-" },
  { id: 9, market: "FT 1X2/BTS: X and Yes", marketCode: "X/++" },
  { id: 10, market: "FT 1X2/Goals: 1 and Over 1.5", marketCode: "1/1.5+" },
  { id: 11, market: "FT 1X2/Goals: 1 and Over 2.5", marketCode: "1/2.5+" },
  { id: 12, market: "FT 1X2/Goals: 1 and Over 3.5", marketCode: "1/3.5+" },
  { id: 13, market: "FT 1X2/Goals: 1 and Under 1.5", marketCode: "1/1.5-" },
];

const Bet = () => {
  const { user } = useSelector((state) => state.auth);
  const [showMarket, setShowMarket] = useState(false);
  const [hideMarketInput, setHideMarketInput] = useState(false);
  const [showSelectedNumber, setShowNumber] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selections, setSelections] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [value, setValue] = useState({ number: null, market: "" });
  const [markets, setMarkets] = useState([]);
  const [games, setGames] = useState([]);
  const [valueNumber, setValueNumber] = useState();
  const [valueMarket, setValueMarket] = useState();
  const dispatch = useDispatch();

  const numberInputRef = useRef(null);
  const marketInputRef = useRef(null);
  const printButtonRef = useRef(null);

  const getData = () => {
    getActiveGames().then((response) => {
      const { data, success } = response;
      if (success) {
        setGames(data.games);
        setMarkets(data.activeMarkets.sort((a, b) => a.marketID - b.marketID));
      } else {
        setGames([]);
        setMarkets([]);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        getData();
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData(); // Initial fetch

    const interval = setInterval(fetchData, 15000); // Adjust interval as needed

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!showSelectedNumber && numberInputRef.current) {
      numberInputRef.current.focus();
    }
  }, [showSelectedNumber]);

  useEffect(() => {
    if (showMarket && !hideMarketInput && marketInputRef.current) {
      marketInputRef.current.focus();
    }
  }, [showMarket, hideMarketInput]);

  const handleBlur = (event) => {
    if (event.key === "Enter") {
      if (!valueNumber) {
        printButtonRef.current.click();
      } else {
        const marketExists = selections.some(
          (item) => item.number === valueNumber
        );

        if (marketExists) {
          // toast.error(
          //   `Game code "${valueNumber}" already exists in selections.`
          // );
          dispatch({
            type: SHOW_MODAL,
            payload: {
              open: true,
              title: "",
              component: <GameAddedModal message={"Game already added"} />,
            },
          });
          event.preventDefault();
          return; // Exit the function to prevent adding a duplicate
        }

        setShowNumber(true);
        setShowMarket(true);
        setHideMarketInput(false);
      }
    }
  };
  console.log(selections, 3453, valueNumber);

  const addToSelections = (event) => {
    let newData = [...selections];
    if (event.key === "Enter") {
      const filteredData = data.filter((item) =>
        item?.marketCode.includes(valueMarket)
      );

      newData = [
        ...newData,
        {
          number: valueNumber,
          market: valueMarket,
          code: filteredData[0].market,
        },
      ];

      setSelections(newData);
      console.log(newData);
      setShowNumber(false);
      setHideMarketInput(true);
      // setSelected([...selected, value]);
      setValueNumber();
      setValueMarket();
    }
  };

  const resetSelections = (event) => {
    setSelections([]);
    setShowNumber(false);
    setHideMarketInput(true);
    // setSelected([...selected, value]);
    setValueNumber();
    setValueMarket();

    numberInputRef.current.focus();
  };

  const removeSelection = (_index_) => {
    setSelections((pre) => {
      return pre.filter((item, index) => index !== _index_);
    });
  };

  console.log(selectedMarket);
  // const onHandleChangeNumber = (event) => {
  //   event.preventDefault();
  //   setValue({ ...value, [event?.target?.name]: event.target.value });
  // };

  const onHandleChange = (event) => {
    event.preventDefault();
    setValueNumber(event.target.value);
  };

  const onHandleChangeMarket = (event) => {
    event.preventDefault();
    setValueMarket(event.target.value);

    setValue({ ...value, number: valueNumber, market: valueMarket });
  };

  return (
    <div className="">
      <div className="Riquadro">
        <div className="CntSX">
          <div className="CntDX">
            <div id="s_w_PC_PC_panelSquare">
              <div className="RiquadroNews Reg">
                <div className="Cnt">
                  <h4
                    className="p-4 commission-head"
                    style={{
                      margin: "1rem 0",
                      background: "white",
                      color: "black",
                    }}
                  >
                    Ticket barcode
                  </h4>
                </div>
                <div className="cashbook card-x">
                  <div className="x-card">
                    <div>
                      <div className="card-head-x flex">
                        <p>Today</p>
                        <p>{formatDate(new Date(), "MMMM DD, YYYY")}</p>
                      </div>
                      <div className="x-card-body flex">
                        <p>Credit Usage</p>
                        <div>
                          <button className="botton-slim bg-yello">
                            0/1,000,000
                          </button>
                        </div>
                      </div>
                      <div className="x-card-footer flex">
                        <p>Sales</p>
                        <button className="botton-slim bg-blue">0(0)</button>
                      </div>
                    </div>
                    <div className="card-head-x-bottom">
                      <div className="head p flex">
                        <p style={{ fontSize: "1rem" }}>Transfers</p>
                        <p>()</p>
                      </div>
                      <div className="body flex p">
                        <h4>Time</h4>
                        <h4>From</h4>
                        <h4>To</h4>
                        <h4>Amount</h4>
                        <h4>Action</h4>
                      </div>
                    </div>
                    <div className="shortcut ">
                      <h5 className="p">Shortcuts Help</h5>
                      <div
                        style={{
                          height: "15rem",
                          overflowY: "auto",
                          paddingBlock: "1rem",
                        }}
                      >
                        {markets?.map((item) => (
                          <div className="flex px">
                            <p>{item?.name}</p>
                            <p>{item?.code}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="x-card-second x-card">
                    <form className="head ">
                      <div className="mb">
                        {showSelectedNumber ? (
                          <div className="flex current-head">
                            <h4>Current Selection</h4>
                            <p className="ml">{valueNumber}</p>
                          </div>
                        ) : (
                          <input
                            placeholder="Selection"
                            type="text"
                            ref={numberInputRef}
                            name="number"
                            value={valueNumber}
                            onChange={(e) => onHandleChange(e)}
                            onKeyDown={handleBlur}
                          />
                        )}
                      </div>
                      {showMarket && (
                        <div>
                          {hideMarketInput ? (
                            <div>
                              <p>{valueMarket}</p>
                            </div>
                          ) : (
                            <input
                              placeholder="Selection"
                              type="text"
                              ref={marketInputRef}
                              name="market"
                              onChange={(e) => onHandleChangeMarket(e)}
                              onKeyDown={addToSelections}
                            />
                          )}
                        </div>
                      )}
                      <h4>Bet Ticket ({selections?.length})</h4>
                    </form>
                    <div>
                      {selections?.map((item, _i_) => (
                        <div
                          style={{
                            justifyContent: "space-between",
                            padding: "0rem 1rem",
                            margin: "0.5rem 0",
                            textAlign: "center",
                            color: "white",
                          }}
                          className="flex bg-yellow "
                        >
                          <div className="flex ">
                            {" "}
                            <p>{item?.number}</p>
                            <p className="ml">{item?.code}</p>
                          </div>
                          <div className="flex ">
                            <button
                              onClick={() => removeSelection(_i_)}
                              type="button"
                              style={{
                                justifySelf: "end",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px",
                                backgroundColor: "red",
                                border: "none",
                                borderRadius: "100%",
                                color: "white",
                              }}
                            >
                              <MdOutlineClose fontSize={20} />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex stake">
                      <h4>Stake</h4>
                      <input className="stake-input" type="text" />
                    </div>
                    <div className="flex stake-btn">
                      <button
                        className="bg-red"
                        button="button"
                        onClick={resetSelections}
                      >
                        Close
                      </button>
                      <button className="bg-yellow">Preview</button>
                      <button
                        className="bg-green"
                        ref={printButtonRef}
                        type="button"
                        onClick={() => {
                          printTicket(data.id, "single");
                        }}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                  <div className="x-card x-card-thrid">
                    <div
                      className="card-head-x-bottom"
                      style={{ marginTop: "-.1rem" }}
                    >
                      <div className="head p flex">
                        <p style={{ fontSize: "1rem" }}>Latest Tickets</p>
                        <p>-</p>
                      </div>
                      <div className="body flex p">
                        <h4>Time</h4>
                        <h4>Bets</h4>
                        <h4>Stake</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bet;
