import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExpensesType } from "../../Services/apis";
import { createExpense, getAllExpenses } from "../../Redux/actions/expenses";
import { toast } from "react-toastify";
import { SHOW_MODAL } from "../../Redux/types";

const CreatExpense = ({ toggle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [inputObject, setObject] = useState({});
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const creatExpense = async (e) => {
    e.preventDefault();
    const payload = {
      expenseTypeId: inputObject?.expense,
      branchId: user?.id,
      // user_id: user?.id,
      amount: inputObject.amount,
      comment: inputObject.comment,
    };
    if (payload.amount === "" || payload.amount === undefined) {
      toast.error("amount value is required");
      return;
    }
    if (payload.expenseTypeId === undefined) {
      toast.error("Select an expense type");
      return;
    }

    await dispatch(createExpense(payload));
    await dispatch(getAllExpenses());
    await dispatch({
      type: SHOW_MODAL,
      payload: {
        open: false,
      },
    });
  };

  useEffect(() => {
    fetchExpenseType();
  }, [dispatch]);

  const fetchExpenseType = async () => {
    setLoading(true);
    const expenseTypes = await getExpensesType();
    if (expenseTypes.success) {
      setDetails(expenseTypes.data);
    }
    if (!expenseTypes.success) {
      toast.error("Internal server eror");
    }
    setLoading(false);
  };

  return (
    <form className="expense-form">
      <div className="expense-input">
        <label>EXPENSE</label>
        <select onChange={handleChange} name="expense">
          <option value="">Select</option>
          {loading
            ? "Loading..."
            : details?.map((type) => (
                <option
                  name="expense"
                  onChange={handleChange}
                  value={type?.id}
                  key={type?.id}
                >
                  {type?.title}
                </option>
              ))}
        </select>
      </div>
      <div className="expense-input">
        <label>AMOUNT</label>
        <input type="number" onChange={handleChange} name="amount" />
      </div>
      <div className="expense-input">
        <label>COMMENT</label>
        <input type="text" onChange={handleChange} name="comment" />
      </div>
      <div className="expense-btn">
        <input
          type="button"
          name="expenses"
          value=" CREATE"
          className="btn-green"
          onClick={(e) => creatExpense(e)}
        />
        <input
          type="button"
          name="expenses"
          value="CANCEL"
          className=" btn-red"
          onClick={() =>
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: false,
              },
            })
          }
        />
      </div>
    </form>
  );
};

export default CreatExpense;
