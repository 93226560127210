import { LOADING, SHOW_MODAL } from "../../Redux/types";
import React, { useState, useEffect } from "react";
import { changePin } from "../../Services/apis";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const CreatePin = ({ toggle }) => {
  const { user } = useSelector((state) => state.auth);
  const [payload, setPayload] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const createUserPin = async (e) => {
    e.preventDefault();
    e.target.value = "Sending...";
    e.target.disable = true;
    setLoading(true);
    let data = {
      pin: payload?.pin,
      confirmPin: payload?.confirmPin,
      userId: user?.id,
      type: "cash",
    };
    changePin(data)
      .then((res) => {
        e.target.disable = false;
        e.target.value = "SUBMIT";
        setLoading(false);
        if (res.status === 1) {
          toast.success(res?.message);

          dispatch({
            type: SHOW_MODAL,
            payload: {
              open: false,
            },
          });
        } else {
          toast.error(res?.message);
          dispatch({
            type: SHOW_MODAL,
            payload: {
              open: false,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: SHOW_MODAL,
          payload: {
            open: false,
          },
        });
        toast.error("Internal server error!");
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="withdraw">
      <form className="new-entry">
        <div className="entry-field">
          <label>Pin</label>
          <input
            type="password"
            name="pin"
            onChange={handleChange}
            value={payload?.pin}
          />
        </div>
        <div className="entry-field">
          <label>Confirm Pin</label>
          <input
            type="password"
            name="confirmPin"
            onChange={handleChange}
            value={payload?.confirmPin}
          />
        </div>
        <div className="entry-field">
          <label>User Id</label>
          <input
            type="number"
            name="userId"
            onChange={handleChange}
            disabled={true}
            value={user?.id}
          />
        </div>
      </form>
      <div className="expense-btn">
        <input
          type="button"
          name="expenses"
          value="CREATE"
          className="btn-green"
          // disabled={loading}
          onClick={createUserPin}
        />
        <input
          type="button"
          name="expenses"
          value="CANCEL"
          className=" btn-red"
          onClick={() => dispatch({ type: SHOW_MODAL, payload: null })}
        />
      </div>
    </div>
  );
};
