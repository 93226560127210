// Modal.js
import React from "react";
import { useDispatch } from "react-redux";
import { SHOW_MODAL } from "../../Redux/types";

const GameAddedModal = ({ message }) => {
  const dispatch = useDispatch();

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-icon">❌</div>
        <p className="modal-message">{message}</p>
        <button
          className="modal-button"
          onClick={() => dispatch({ type: SHOW_MODAL, payload: null })}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default GameAddedModal;
