import { REMOVE_USER_DATA, UPDATE_USER_BALANCE } from "../Redux/types";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { formatNumber, openFastCode } from "../Utils/helpers";
import { useSelector } from "react-redux";
import { authDetails } from "../Services/apis";
import { Fragment } from "react";

export const AppHeader = ({ user, dispatch, isAuthenticated }) => {
  const { loading } = useSelector((state) => state.login);
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const [refreshing, setRefresh] = useState(false);

  const refreshBalance = () => {
    setRefresh(true);
    authDetails()
      .then((resp) => {
        setRefresh(false);
        if (resp.user) {
          const user = resp.user;
          dispatch({
            type: UPDATE_USER_BALANCE,
            payload: user.available_balance,
          });
        }
      })
      .catch((err) => {
        setRefresh(false);
      });
  };

  return (
    <Fragment>
      <div id="divHeader">
        {loading && (
          <div className="MenuUpdate">
            <div role="status" aria-hidden="true">
              Please wait&nbsp;
              <img
                src="/img/wait_top.gif"
                align="absmiddle"
                style={{ borderWidth: "0px" }}
                alt="waiting"
              />
            </div>
          </div>
        )}
        <div className="content_Header">
          <div className="Logo">
            <a href="/" id="h_w_lnkHP">
              <img
                id="h_w_Image1"
                src="/img/logo.png"
                style={{ borderWidth: "0px", width: "130px" }}
                alt="logo"
              />
            </a>
          </div>
          <div className="Tabs">
            <ul>
              <li>
                <NavLink className="sel" title="Sport" to="/Sport/Cashdesk">
                  Sport
                </NavLink>
              </li>
              <li>
                <a
                  title="Live Viewer"
                  href="https://live.raimax.bet/"
                  target="_blank"
                >
                  Live Viewer TV
                </a>
              </li>
              <li>
                <a
                  title="Soccer Print"
                  href={`${process.env.REACT_APP_BASEURL}/soccer-print`}
                  target="_blank"
                >
                  Soccer Print
                </a>
              </li>
              <li>
                <a
                  title="Today's Print"
                  href={`${process.env.REACT_APP_BASEURL}/today-print`}
                  target="_blank"
                >
                  Today's Print
                </a>
              </li>
              <li>
                <a title="Cashdesk" href="/Sport/Cashdesk">
                  Cashdesk
                </a>
              </li>
              <li>
                <a title="Races" href="/Account/Cash-Book">
                  CashBook
                </a>
              </li>
              <li>
                <a
                  title="Live Viewer"
                  href="https://bit.ly/cashier1000"
                  target="_blank"
                >
                  Download Luckyballs
                </a>
              </li>
            </ul>
          </div>
          <div className="GMTPanel">
            Timezone:
            <div className="GMT_content_wrapper True">
              <div className="GMTFlags ">
                <div className="GMT41 Sel">
                  <a
                    href="../ChooseGMT.aspx?ID=41&amp;url=/Sport/Default.aspx"
                    className="lnkGMTBtn"
                    title="GMT+01:00"
                  >
                    {process.env.REACT_APP_REGION}
                  </a>
                </div>
              </div>
              <div id="btnGMTList">
                <a id="btnGMTList_link" href="#" />
              </div>
            </div>
          </div>
          <div className="Lang_content_wrapper">
            <div className="languageFlags">
              <div className="en-GB Sel">
                <a
                  href="../ChooseLanguage.aspx?ID=2&amp;url=/Sport/Default.aspx"
                  className="lnkLangBtn"
                  title="English"
                >
                  English
                </a>
              </div>
            </div>
            <div id="btnLangList">
              <a id="btnLangList_link" href="#" />
            </div>
          </div>

          {isAuthenticated && (
            <div className="AreaRiservata">
              <div className="divLoginLogged">
                <div></div>
                <table
                  className="tblLoginLogged"
                  cellPadding="0"
                  cellSpacing="3"
                  width="100%"
                >
                  <tbody>
                    <tr>
                      <td className="tdUser">
                        <div id="hl_w_cLogin_UpPanelLogin">
                          <span className="IDUtente">{user?.code}</span>&nbsp;
                          {user?.username}&nbsp;&nbsp;
                        </div>
                      </td>
                      <td>
                        <NavLink
                          className="btnLogout"
                          to="/Login"
                          onClick={() => dispatch({ type: REMOVE_USER_DATA })}
                        >
                          Logout
                        </NavLink>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div id="divSaldo">
                  <span>
                    <div className="TitoloValuta">
                      <span>Currency</span>:<span>NGN</span>
                    </div>
                    <div className="TitoloSaldo">
                      <span>Availability</span>:
                      <span>
                        {formatNumber(user?.availableBalance)}&nbsp;
                        {SportsbookGlobalVariable.Currency}
                      </span>
                      {!refreshing ? (
                        <input
                          type="image"
                          name="hl$w$cLogin$btnSaldo"
                          className="btnRefreshSaldo"
                          src="/img/Refresh_ico.png"
                          alt="Refresh balance"
                          align="absmiddle"
                          onClick={refreshBalance}
                          style={{ borderWidth: "0px" }}
                        />
                      ) : (
                        <i className={`fa fa-spin fa-spinner`} />
                      )}
                    </div>
                    {/* {user?.role !== 'Cashier' && 
                    <div className="divSaldoUtente">
                      <span>
                        {user?.balance < 0 && 'You owe the company'}
                        {user?.balance > 0 && 'The company owes you'}
                      </span>:&nbsp;
                      <span>
                        {formatNumber(Math.abs(user?.balance))}&nbsp;{SportsbookGlobalVariable.Currency}</span>
                    </div>} */}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="container_TopMenu">
        <div className="topMenu">
          <div className="tblMenu">
            <ul>
              <li>
                <a title="Home" href="/Home">
                  Home
                </a>
              </li>
              <li>
                <a title="About Us" href="/Pages/aboutus/content">
                  About Us
                </a>
              </li>
              <li>
                <a title="Tutorials" href="/Pages/Tutorial_main/content">
                  Tutorials
                </a>
              </li>
              <li>
                <a title="FAQ" href="/Pages/Sport_header_Menu_Faqs/content">
                  FAQ
                </a>
              </li>
              {/* <li>
                <a
                  title="Deposit/Withdrawal"
                  href="/TPAutologin.aspx?Destinazione=HowtoDepositWithdraw"
                  target="_blank"
                >
                  Deposit/Withdrawal
                </a>
              </li> */}
              <li>
                <a title="Livescore" href="/Sport/Livescore">
                  Livescore
                </a>
              </li>
              <li>
                <a
                  title="Results"
                  href={`${process.env.REACT_APP_BASEURL}/results`}
                >
                  Results
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  title="Smart Code"
                  onClick={openFastCode}
                >
                  Smart Code
                </a>
              </li>
              <li>
                <a title="Contact Us" href="/Pages/contactus/content">
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  title="LUcky Bet viewr"
                  href="https://games.elbet.com/lucky-balls/index.html?referer=online.sportsbookengine.elbet.com"
                  target="_blank"
                >
                  Luckyballs Viewer
                </a>
              </li>
            </ul>
          </div>
          <div id="LP_shop_notlogged" className="liveChat" />
        </div>
      </div>
    </Fragment>
  );
};
