import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../Styles/_bonus.scss";
import { BonusTypeCard } from "../../Components/BonusTypeCard";
import { BonusMathsCard } from "../../Components/BonusMathsCard";
/**
 * packages
 */

export const Bonus = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [dispatch]);

  const click = (item) => {
    setDetails(item);
  };

  return (
    <div className="bonus">
      <BonusTypeCard clickBtn={click} />
    </div>
  );
};
