import {
  GET_EXPENSES_STARTED,
  GET_EXPENSES_SUCCESSFUL,
  GET_EXPENSES_FAILED,
  GET_EXPENSE_STARTED,
  GET_EXPENSE_SUCCESSFUL,
  GET_EXPENSE_FAILED,
  POST_EXPENSES_STARTED,
  POST_EXPENSES_SUCCESSFUL,
  POST_EXPENSES_FAILED,
  GET_EXPENSE_TYPE_STARTED,
  GET_EXPENSES_TYPE_SUCCESSFUL,
  GET_EXPENSES_TYPE_FAILED,
  UPDATE_EXPENSES_STARTED,
  UPDATE_EXPENSES_SUCCESSFUL,
  UPDATE_EXPENSES_FAILED,
  DELETE_EXPENSES_STARTED,
  DELETE_EXPENSES_SUCCESSFUL,
  DELETE_EXPENSES_FAILED,
  GENERATE_REPORT_STARTED,
  GENERATE_REPORT_SUCCESSFUL,
  GENERATE_REPORT_FAILED,
} from "../types";

import {
  getExpenses,
  postExpense,
  getExpensesType,
  putExpense,
  deleteExpense,
  getExpense,
  generate,
  fetchReport,
} from "../../Services/apis";
import { ErrorPopUp, SuccessPopUp } from "../../Utils/toastify";

export const postExpenseStarted = () => {
  return {
    type: POST_EXPENSES_STARTED,
  };
};

export const postExpenseSuccess = (payload) => {
  return {
    type: POST_EXPENSES_SUCCESSFUL,
    payload,
  };
};

export const postExpenseFailed = (payload) => {
  return {
    type: POST_EXPENSES_FAILED,
    payload,
  };
};

export const updateExpenseStarted = () => {
  return {
    type: UPDATE_EXPENSES_STARTED,
  };
};

export const updateExpenseSuccess = (payload) => {
  return {
    type: UPDATE_EXPENSES_SUCCESSFUL,
    payload,
  };
};

export const updateExpenseFailed = (payload) => {
  return {
    type: UPDATE_EXPENSES_FAILED,
    payload,
  };
};

export const deleteExpenseStarted = () => {
  return {
    type: DELETE_EXPENSES_STARTED,
  };
};

export const deleteExpenseSuccess = (payload) => {
  return {
    type: DELETE_EXPENSES_SUCCESSFUL,
    payload,
  };
};

export const deleteExpenseFailed = (payload) => {
  return {
    type: DELETE_EXPENSES_FAILED,
    payload,
  };
};

export const getExpenseTypeStarted = () => {
  return {
    type: GET_EXPENSE_TYPE_STARTED,
  };
};

export const getExpenseTypeSuccess = (payload) => {
  return {
    type: GET_EXPENSES_TYPE_SUCCESSFUL,
    payload,
  };
};

export const getExpenseTypeFailed = (payload) => {
  return {
    type: GET_EXPENSES_TYPE_FAILED,
    payload,
  };
};

export const getExpenseStarted = () => {
  return {
    type: GET_EXPENSES_STARTED,
  };
};

export const getExpenseSuccess = (payload) => {
  return {
    type: GET_EXPENSES_SUCCESSFUL,
    payload,
  };
};

export const getExpensesFailed = (payload) => {
  return {
    type: GET_EXPENSES_FAILED,
    payload,
  };
};

export const generateReportStarted = () => {
  return {
    type: GENERATE_REPORT_STARTED,
  };
};

export const generateReportSuccess = (payload) => {
  return {
    type: GENERATE_REPORT_SUCCESSFUL,
    payload,
  };
};

export const generateReportFailed = (payload) => {
  return {
    type: GENERATE_REPORT_FAILED,
    payload,
  };
};

export const getOneExpenseStarted = () => {
  return {
    type: GET_EXPENSE_STARTED,
  };
};

export const getOneExpenseSuccess = (payload) => {
  return {
    type: GET_EXPENSE_SUCCESSFUL,
    payload,
  };
};

export const getOneExpenseFailed = (payload) => {
  return {
    type: GET_EXPENSE_FAILED,
    payload,
  };
};

export const getAllExpenseType = () => async (dispatch) => {
  try {
    dispatch(getExpenseTypeStarted());
    const response = await getExpensesType();
    const { data } = response;
    return dispatch(getExpenseTypeSuccess(data));
  } catch (error) {
    return dispatch(getExpenseTypeFailed(error.response));
  }
};

export const fetchBranchreport = (date) => async (dispatch) => {
  try {
    const response = await fetchReport(date);
    console.log(response, "response");
    const { data } = response;

    return dispatch(getExpenseSuccess(data));
  } catch (error) {
    return dispatch(getExpensesFailed(error.response));
  }
};

export const getAllExpenses = () => async (dispatch) => {
  try {
    dispatch(getExpenseStarted());
    const response = await getExpenses();
    const { data } = response;

    return dispatch(getExpenseSuccess(data));
  } catch (error) {
    return dispatch(getExpensesFailed(error.response));
  }
};

export const getExpenseById = (id) => async (dispatch) => {
  try {
    dispatch(getOneExpenseStarted());
    const response = await getExpense(id);
    const { data } = response;
    return dispatch(getOneExpenseSuccess(data));
  } catch (error) {
    return dispatch(getOneExpenseFailed(error.response));
  }
};

export const generateReport = (payload) => async (dispatch) => {
  try {
    dispatch(generateReportStarted());
    const response = await generate(payload);

    return dispatch(generateReportSuccess(response));
  } catch (error) {
    return dispatch(generateReportFailed(error.response));
  }
};

export const createExpense = (payload) => async (dispatch) => {
  try {
    dispatch(postExpenseStarted());
    const response = await postExpense(payload);
    const { data } = response;
    SuccessPopUp("Expense Successfully Created");
    return dispatch(postExpenseSuccess(data));
  } catch (error) {
    ErrorPopUp("Error occured");
    return dispatch(postExpenseFailed(error.response));
  }
};

export const updateExpense = (payload) => async (dispatch) => {
  const { id, ...data } = payload;
  try {
    dispatch(updateExpenseStarted());
    const response = await putExpense(id, data);
    SuccessPopUp("Expense Successfully Created");
    return dispatch(updateExpenseSuccess(response?.data));
  } catch (error) {
    ErrorPopUp("Error occured");
    return dispatch(postExpenseFailed(error.response));
  }
};

export const deleteExpenseItem = (id) => async (dispatch) => {
  try {
    dispatch(deleteExpenseStarted());
    const response = await deleteExpense(id);
    SuccessPopUp("Expense Successfully Deleted");
    return dispatch(deleteExpenseSuccess(response?.data));
  } catch (error) {
    ErrorPopUp("Error occured");
    return dispatch(deleteExpenseFailed(error?.response?.data?.message));
  }
};
