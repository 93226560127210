import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  processUserTransfer,
  validateUserDepositCode,
} from "../../Services/apis";
import { toast } from "react-toastify";
import { SHOW_MODAL, UPDATE_USER_BALANCE } from "../../Redux/types";
import { formatNumber } from "../../Utils/helpers";

const PlayerDeposit = ({ toggle }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [validCode, setValidCode] = useState(false);
  const [code, setCode] = useState("");
  const [transaction, setTransaction] = useState(null);
  const clientId = process.env.REACT_APP_CLIENT_ID;

  const doValidate = async (e) => {
    e.preventDefault();
    e.target.value = "VALIDATING...";
    e.target.disable = true;
    setLoading(true);
    let data = {
      code: code,
      userRole: user?.role,
    };

    validateUserDepositCode(clientId, data)
      .then((res) => {
        e.target.disable = false;
        e.target.value = "SUBMIT";
        setLoading(false);
        if (res.success) {
          setTransaction(res?.data);
          setValidCode(true);
          toast.success("Transaction found");
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("Internal server error!");
        setLoading(false);
      });
  };

  const doTransfer = async (e) => {
    e.target.value = "LOADING...";
    e.target.disable = true;
    setLoading(true);
    if (!loading) {
      processUserTransfer(transaction.id, transaction?.client_id, user?.role)
        .then((res) => {
          e.target.disable = false;
          e.target.value = "Transfer";
          setLoading(false);
          if (res.success) {
            dispatch({
              type: UPDATE_USER_BALANCE,
              payload: res.data.balance,
            });
            toast.success(res.message);
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: false,
              },
            });
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error("Internal server error!");
          setLoading(false);
          e.target.disable = false;
          e.target.value = "Transfer";
        });
    }
  };

  return (
    <div className="expense-form">
      {!transaction && (
        <>
          <div className="expense-input">
            <label>DEPOSIT CODE</label>
            <input
              type="text"
              onChange={(e) => setCode(e.target.value)}
              name="amount"
              placeholder="Enter deposit code from customer"
            />
          </div>

          <div className="expense-btn">
            <input
              type="button"
              name="expenses"
              value={loading ? "LOADING..." : "VALIDATE"}
              className="btn-green"
              onClick={(e) => doValidate(e)}
            />
            <input
              type="button"
              name="expenses"
              value="CANCEL"
              className="btn-red"
              onClick={() =>
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    open: false,
                  },
                })
              }
            />
          </div>
        </>
      )}
      {validCode && (
        <div className="account form p15 pb-4" id="change-password">
          <table width="100%" cellSpacing="0" className="tblPP">
            <tbody>
              <tr>
                <td className="cellaSx" width="100%">
                  To User
                </td>
                <td className="cellaSx">:</td>
                <td className="cellaDx">{transaction?.username}</td>
                <td className="rfv"></td>
              </tr>
              <tr>
                <td className="cellaSx">Amount</td>
                <td className="cellaSx">
                  <b>:</b>
                </td>
                <td className="cellaDx">{formatNumber(transaction.amount)}</td>
                <td className="rfv"></td>
              </tr>
              <tr>
                <td className="cellaSx">Available Balance</td>
                <td className="cellaSx">
                  <b>:</b>
                </td>
                <td className="cellaDx">
                  {formatNumber(parseInt(user?.availableBalance))}
                </td>
                <td className="rfv"></td>
              </tr>
              <tr>
                <td colSpan="4">
                  <div className="spacer5" />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="expense-btn">
            <input
              type="button"
              name="expenses"
              value={loading ? "TRANSFERING..." : "TRANSFER"}
              className="btn-green"
              disabled={loading}
              onClick={(e) => doTransfer(e)}
            />
            <input
              type="button"
              name="expenses"
              value="CANCEL"
              className="btn-red"
              onClick={() => {
                setTransaction(null);
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    open: false,
                  },
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerDeposit;
