import React, { useEffect, useState } from "react";
import { generate, lastApproved } from "../../Services/apis";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_MODAL } from "../../Redux/types";
import GenerateForm from "../../Components/GenerateForm";

/**
 * packages
 */

export const LastApproved = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const generateData = async () => {
    setLoading(true);
    const res = await lastApproved();
    setLoading(false);
    setDetails(res?.data);
  };

  useEffect(() => {
    generateData();
  }, []);

  const generateReport = async ({ e, inputObject }) => {
    e.preventDefault(e);
    setLoading(true);

    const payload = {
      agent_id: user.id,
      other_sales: inputObject?.otherSales,
      other_payout: inputObject?.otherPayout,
    };
    // const res = await lastApproved(payload);
    // console.log(res, "rewrwerreww");
    // setLoading(false);
    // setDetails(res?.data);

    closeModal();
  };

  const closeModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        open: false,
      },
    });
  };

  return (
    <>
      <div className="homeShop last-approved">
        <div className="table">
          <div className="head">
            <h4>Last Approved ()</h4>
            <div>
              <button
                onClick={() =>
                  dispatch({
                    type: SHOW_MODAL,
                    payload: {
                      open: true,
                      title: "Generate Report",
                      component: (
                        <GenerateForm generateReport={generateReport} />
                      ),
                    },
                  })
                }
              >
                + New Entry
              </button>
            </div>
          </div>
          {details && (
            <div>
              <div className="flex">
                <p>
                  <strong>Opening Balance</strong>
                </p>
                <p>{details?.openingBalance}</p>
              </div>
              <div className="flex">
                <p>
                  <strong>Normal Sales</strong>
                </p>
                <p>{details?.normalSales}</p>
              </div>
              <div className="flex">
                <p>
                  <strong>Normal Payout</strong>
                </p>
                <p>{details?.normalPayouts}</p>
              </div>
              {/* <div className="flex">
                <p>
                  <strong>Virtual Sales</strong>
                </p>
                <p>{details?.virtual_sales}</p>
              </div> */}
              <div className="flex">
                <p>
                  <strong>Online Sales</strong>
                </p>
                <p>{details?.onlineSales}</p>
              </div>
              <div className="flex">
                <p>
                  <strong>Online Payout</strong>
                </p>
                <p>{details?.onlinePayouts}</p>
              </div>
              {/* <div className="flex">
                <p>
                  <strong>Virtual Payout</strong>
                </p>
                <p>{details?.virtual_payout}</p>
              </div> */}
              <div className="flex">
                <p>
                  <strong>Cash In</strong>
                </p>
                <p>{details?.cashin}</p>
              </div>
              <div className="flex">
                <p>
                  <strong>Cash Out</strong>
                </p>
                <p>{details?.cashout}</p>
              </div>
              <div className="flex">
                <p>
                  <strong>Expenses</strong>
                </p>
                <p>{details?.expenses}</p>
              </div>

              <div className="flex">
                <p>
                  <strong>Other Sales</strong>
                </p>
                <p>{details?.otherSales}</p>
              </div>
              <div className="flex">
                <p>
                  <strong>Other Payout</strong>
                </p>
                <p>{details?.otherPayouts}</p>
              </div>
              {/* <div className="flex">
                <p>
                  <strong>Casino Sales</strong>
                </p>
                <p>{details?.casino_sales}</p>
              </div> */}

              {/* <div className="flex">
                <p>
                  <strong>Online Withdrawal</strong>
                </p>
                <p>{details?.online_withdrawal}</p>
              </div> */}
              <div className="flex">
                <p>
                  <strong>Closing Balance</strong>
                </p>
                <p>{details?.closingBalance}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
