import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, tranferFundToUser } from "../../Services/apis";
import { toast } from "react-toastify";
import { SHOW_MODAL, UPDATE_USER_BALANCE } from "../../Redux/types";

const UserTransfer = ({ toggle }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [transferData, setTranferData] = useState({
    amount: "",
    pin: "",
    toUsername: "",
  });

  const transferFund = async (e) => {
    e.preventDefault();
    e.target.value = "Sending...";
    e.target.disable = true;
    setLoading(true);
    tranferFundToUser(transferData)
      .then((res) => {
        e.target.disable = false;
        e.target.value = "SUBMIT";
        setLoading(false);
        toggle();
        if (res.success) {
          setTransaction(res?.data);
          getDetails();
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toggle();
        toast.error("Internal server error!");
        setLoading(false);
      });
  };

  const getDetails = async () => {
    getUserDetails()
      .then((res) => {
        if (res.success) {
          dispatch({
            type: UPDATE_USER_BALANCE,
            payload: res?.data?.availableBalance,
          });
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error("Internal server error!");
      });
  };
  const handleChange = (e) => {
    setTranferData({
      ...transferData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="expense-form">
      <>
        <div className="expense-input">
          <label>Amount</label>
          <input
            type="text"
            onChange={handleChange}
            name="amount"
            placeholder="Enter transfer amount"
          />
        </div>
        <div className="expense-input">
          <label>Username</label>
          <div
            style={{
              display: "flex",
              border: "1px solid #d2d6de",
              color: "black",
              paddingLeft: "10px",
            }}
          >
            <p style={{ borderRight: "#d2d6de" }}>+211</p>
            <input
              style={{
                border: "none",
              }}
              type="text"
              onChange={handleChange}
              name="toUsername"
              placeholder="Enter customer Username"
            />
          </div>
        </div>
        <div className="expense-input">
          <label>PIN</label>

          <input
            type="number"
            onChange={handleChange}
            name="pin"
            placeholder="Enter customer pin"
          />
        </div>
        <div className="expense-btn">
          <input
            type="button"
            name="expenses"
            value={loading ? "LOADING..." : "Transfer"}
            className="btn-green"
            onClick={(e) => transferFund(e)}
          />
          <input
            type="button"
            name="expenses"
            value="CANCEL"
            className="btn-red"
            onClick={() =>
              dispatch({
                type: SHOW_MODAL,
                payload: {
                  open: false,
                },
              })
            }
          />
        </div>
      </>

      {/* {validCode && (
        <div className="account form p15 pb-4" id="change-password">
          <table width="100%" cellSpacing="0" className="tblPP">
            <tbody>
              <tr>
                <td className="cellaSx" width="100%">
                  To User
                </td>
                <td className="cellaSx">:</td>
                <td className="cellaDx">{transaction?.username}</td>
                <td className="rfv"></td>
              </tr>
              <tr>
                <td className="cellaSx">Amount</td>
                <td className="cellaSx">
                  <b>:</b>
                </td>
                <td className="cellaDx">{formatNumber(transaction.amount)}</td>
                <td className="rfv"></td>
              </tr>
              <tr>
                <td className="cellaSx">Available Balance</td>
                <td className="cellaSx">
                  <b>:</b>
                </td>
                <td className="cellaDx">
                  {formatNumber(parseInt(user?.availableBalance))}
                </td>
                <td className="rfv"></td>
              </tr>
              <tr>
                <td colSpan="4">
                  <div className="spacer5" />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="expense-btn">
            <input
              type="button"
              name="expenses"
              value={loading ? "TRANSFERING..." : "TRANSFER"}
              className="btn-green"
              disabled={loading}
              onClick={(e) => doTransfer(e)}
            />
            <input
              type="button"
              name="expenses"
              value="CANCEL"
              className="btn-red"
              onClick={() => {
                setTransaction(null);
                dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    open: false,
                  },
                });
              }}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default UserTransfer;
