import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getCashiers } from "../../Services/apis";
import { Spinner } from "reactstrap";

const OnlineSales = () => {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const generateData = async () => {
    setLoading(true);

    await getCashiers()
      .then((r) => {
        setLoading(false);
        setDetails(r?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    generateData();
  }, []);

  useEffect(() => {
    document.body.classList = "bodyMain en-GB sport_master user Logged";
  }, []);

  return (
    <div className="">
      <div className="Riquadro">
        <div className="CntSX">
          <div className="CntDX">
            <div id="s_w_PC_PC_panelSquare">
              <div className="RiquadroNews Reg">
                <div className="Cnt" style={{ marginTop: "-1rem" }}>
                  <h4 className="p-4 commission-head">Online Sales Report</h4>
                </div>
                <div className="cashbook">
                  <div className="table-bottom">
                    <table
                      style={{
                        borderWidth: "0px",
                        borderStyle: "none",
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tr
                        style={{
                          borderWidth: "0px",
                          borderBottom: "1px solid #f4f4f4",
                          width: "100%",
                          borderCollapse: "collapse",
                          background: "transparent",
                          color: "#4c4c4c",
                          textAlign: "center",
                        }}
                      >
                        <th
                          align="center"
                          scope="col"
                          style={{ padding: ".7rem" }}
                        >
                          Name
                        </th>
                        <th align="center" scope="col">
                          Network Balance
                        </th>
                        <th align="center" scope="col">
                          Balance
                        </th>
                      </tr>
                      <tbody>
                        {loading ? (
                          <Spinner style={{ textAlign: "center" }} />
                        ) : (
                          details &&
                          details?.map((item, i) => (
                            <tr className="dgItemStyle" key={i}>
                              <td align="center">
                                {" "}
                                {moment(item?.date).format("DD/MM/YYYY")}
                              </td>
                              <td align="center"> {item?.name}</td>
                              <td align="center"> {item?.balance}</td>
                              <td align="center">{item?.network_balance}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineSales;
