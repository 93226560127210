import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCashOuts } from "../../Services/apis";
import { SHOW_MODAL, CASHOUT_DETAILS } from "../../Redux/types";
import Confirm from "../../Components/Modal/Confirm";

/**
 * packages
 */

export const CashOut = () => {
  const dispatch = useDispatch();
  const { cashout } = useSelector((state) => state.auth);

  // const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   fetchCashout();
  // }, [dispatch, SHOW_MODAL]);
  function formatDate(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  }

  useEffect(() => {
    fetchCashout();
  }, []);

  const fetchCashout = async () => {
    const cashout = await getCashOuts();
    if (cashout.success) {
      dispatch({
        type: CASHOUT_DETAILS,
        payload: {
          cashout: cashout.data,
        },
      });
    }
  };
  return (
    <>
      <div className="homeShop logged">
        <table
          style={{
            borderWidth: "0px",
            borderStyle: "none",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <tr
            style={{
              borderWidth: "0px",
              borderBottom: "1px solid #f4f4f4",
              width: "100%",
              borderCollapse: "collapse",
              background: "transparent",
              color: "#4c4c4c",
              textAlign: "center",
            }}
            className="dgHdrStyle"
          >
            <th align="center" scope="col">
              ID
            </th>
            <th align="center" scope="col">
              DATE
            </th>
            <th align="center" scope="col">
              AMOUNT
            </th>
            <th align="center" scope="col">
              STATUS
            </th>
            <th align="center" scope="col">
              COMMENT
            </th>
            <th align="center" scope="col">
              Action
            </th>
          </tr>
          {loading ? (
            <tr style={{ textAlign: "center", width: "100%" }}>
              <h3>LOADING.....</h3>{" "}
            </tr>
          ) : (
            cashout.cashout &&
            cashout.cashout.map((item, i) => {
              const currentDate = new Date(item?.createdAt);
              const formattedDate = formatDate(currentDate);
              return (
                <tr className="dgItemStyle" key={i}>
                  <td align="center">{item?.id}</td>
                  <td align="center"> {formattedDate}</td>
                  <td align="center">{item?.amount}</td>
                  <td align="center">
                    {item?.status === 0 ? "Pending" : "Approved"}
                  </td>
                  <td align="center">{item?.comment}</td>
                  <td align="center">
                    <button
                      className="green"
                      onClick={() => {
                        if (item.status !== 0) return;
                        dispatch({
                          type: SHOW_MODAL,
                          payload: {
                            open: true,
                            title: "Confirm Cashout",
                            component: (
                              <Confirm
                                title={JSON.stringify({
                                  id: item.id,
                                  type: "cashout",
                                })}
                              />
                            ),
                          },
                        });
                      }}
                      style={{
                        background: "#000229",
                        color: "white",
                        marginRight: "1rem",
                        border: "0px",
                        padding: ".3rem 1rem",
                        cursor: "pointer",
                      }}
                    >
                      {item?.status === 0 ? "Confirm" : "Confirmed"}
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </table>
      </div>
    </>
  );
};
