import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../Styles/_deposit.scss";
import { useParams } from "react-router-dom";
import { formatNumber } from "../../Utils/helpers";
import { LOADING } from "../../Redux/types";
import { initializeTransaction } from "../../Services/apis";

const Transfer = ({ history }) => {
  const id = useParams();
  const { user } = useSelector((state) => state.auth);
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const dispatch = useDispatch();
  const [busy, setBusy] = useState(false);
  const [amount, setAmount] = useState(0);

  const updateAmount = (value) => {
    if (value === 0) {
      setAmount(0);
      return;
    }
    let currentAmount = amount;
    if (currentAmount === "") {
      currentAmount = 0;
    }
    const newAmount = currentAmount + value;
    setAmount(newAmount);
  };

  const submit = (e) => {
    e.preventDefault();
    setBusy(true);
    dispatch({ type: LOADING, payload: { show: true, message: "" } });
    initializeTransaction({ amount: amount, payment_method: "mgurush" })
      .then((res) => {
        dispatch({
          type: LOADING,
          payload: { show: false, message: "" },
        });
        setBusy(false);

        if (res.success) {
          setAmount("");
          window.location.href = res.url;
        } else {
          dispatch({
            type: LOADING,
            payload: { show: true, message: res.message, color: "danger" },
          });
        }
      })
      .catch((err) => {
        setBusy(false);
        dispatch({
          type: LOADING,
          payload: { show: false, message: "" },
        });
        dispatch({
          type: LOADING,
          payload: {
            show: true,
            title: "Error",
            message: "Unable to process request. Please try again",
          },
        });
      });
  };

  const selectType = () => {};

  return (
    <div className="deposit">
      <div className="deposit-step">
        <div className="left">
          <h3 style={{ textTransform: "capitalize" }}>{id.id}</h3>
          <div className="transfer-btn">
            <button onClick={() => selectType()}>Transfer with Code</button>
            <button onClick={() => selectType()}>Transfer to User</button>
          </div>
        </div>
        <div className="right">
          <div className="top">
            <h4>Balance: {user?.available_balance}</h4>
          </div>
          <p className="pl-1">T</p>
          <div className="flex by-1">
            <p>Email: </p>
            <h5>{user?.email} </h5>
          </div>
          <div>
            <div className="flex">
              <label className="w-2"></label>
              <ul className="flex-list">
                <li onClick={() => updateAmount(1000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  +1000
                </li>
                <li onClick={() => updateAmount(5000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  +5,000
                </li>
                <li onClick={() => updateAmount(10000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  +10,000
                </li>{" "}
                <li onClick={() => updateAmount(25000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  +25,000
                </li>{" "}
                <li onClick={() => updateAmount(50000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  50,000
                </li>
              </ul>
            </div>
            <div className="flex my-1">
              <label className="w-2">Amount</label>
              <input
                name="amount"
                type="number"
                autoComplete="off"
                step="100"
                maxLength="5"
                min="100"
                max="10000"
                className="deposit-input"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />
            </div>
          </div>
          <div className="flex-list jb by-1">
            <div>
              <p>Fees:None </p>
            </div>
            <div>
              <p>Minimum Deposit:{SportsbookGlobalVariable.Currency} 1000 </p>
            </div>
          </div>
          <div className="flex-list pl-1">
            <p>Currency: {SportsbookGlobalVariable.Currency} </p>
          </div>
          <div className="flex-list jb by-1">
            <div>
              {/* <button className="btn start">+ Deposit</button> */}
              <button
                className="btn start mt20 mb20"
                disabled={parseInt(amount) === 0 || busy}
                onClick={submit}
              >
                {" "}
                {busy ? "Processing..." : "Make Payment"}
              </button>
            </div>
            <div>
              <button className="btn red" onClick={() => history.goBack()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
