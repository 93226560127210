export const SET_SPORTS = "SET_SPORTS";
export const SET_SPORT = "SET_SPORT";
export const SET_ACTIVE_PERIOD = "SET_ACTIVE_PERIOD";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_TOURNAMENTS = "SET_TOURNAMENT";
export const SET_FIXTURES = "SET_FIXTURES";
export const SET_FIXTURE = "SET_FIXTURE";

export const SET_USER_DATA = "[USER] SET DATA";
export const UPDATE_USER_DATA = "[USER] UPDATE USER DATA";
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";
export const UPDATE_USER_BALANCE = "[USER] UPDATE BALANCE";
export const UPDATE_USERNAME = "[USER] UPDATE USERNAME";

export const CASHIN_DETAILS = "[CASHIN] DETAILS";
export const CASHOUT_DETAILS = "[CASHOUT] DETAILS";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOADING = "LOADING";

export const SET_COUPON_DATA = "[COUPON] SET DATA";
export const SET_POOL_COUPON_DATA = "[POOL] SET POOL COUPON";
export const SET_WEEKLY_COUPON_DATA = "[COUPON] SET WEEKLY COUPON";
export const SET_COUPON_TYPE = "[COUPON] SET TYPE";
export const SET_USE_BONUS = "[COUPON] USE BONUS";
export const SET_BETSLIP_DATA = "[COUPON] SET BETSLIP";
export const SET_LOADED_DATA = "[COUPON] LOAD DATA";
export const RESET_COUPON_AMOUNT = "[COUPON] RESET AMOUNT";
export const SET_BET_PLACED = "[COUPON] SET BET PLACED";
export const SET_TODAYS_BET = "[COUPON] SET TODAYs BET";
export const REMOVE_SELECTION = "[COUPON] REMOVE SELECTION";
export const CANCEL_BET = "[COUPON] CANCEL BET";
export const ACCEPT_CHANGES = "[COUPON] ACCEPT CHANGES";
export const CONFIRM_BET = "[COUPON] CONFIRM BET";
export const ADD_LIVE_ODDS = "[COUPON] ADD_LIVE ODDS";
export const UPDATE_LIVE_ODDS = "[COUPON] UPDATE_LIVE ODDS";
export const SHOW_BONUS_CONFIRM = "SHOW BONUS CONFIRM MODAL";
export const SET_POOL_STAKE = "[POOL] SET STAKE";

export const SET_BONUS_LIST = "SET BONUS LIST";
export const SET_GLOBAL_VAR = "SET GLOBAL VARIABLES";
export const SHOW_MODAL = "SHOW MODAL";
export const SHOW_DEPOSIT_MODAL = "SHOW DEPOSIT MODAL";
export const SHOW_LOGIN_MODAL = "SHOW LOGIN MODAL";
export const SHOW_TIPSTER_BET = "SHOW TIPSTER BET";
export const SHOW_TIPSTER_MODAL = "SHOW TIPSTER MODAL";
export const SHOW_PASSWORD_MODAL = "SHOW PASSWORD MODAL";

export const GET_EXPENSES_STARTED = "GET_EXPENSES_STARTED";
export const GET_EXPENSES_SUCCESSFUL = "GET_EXPENSES_SUCCESSFUL";
export const GET_EXPENSES_FAILED = "GET_EXPENSES_FAILED";

export const GET_EXPENSE_STARTED = "GET_EXPENSE_STARTED";
export const GET_EXPENSE_SUCCESSFUL = "GET_EXPENSE_SUCCESSFUL";
export const GET_EXPENSE_FAILED = "GET_EXPENSE_FAILED";

export const GET_EXPENSE_TYPE_STARTED = "GET_EXPENSES_TYPE_STARTED";
export const GET_EXPENSES_TYPE_SUCCESSFUL = "GET_EXPENSES_TYPE_SUCCESSFUL";
export const GET_EXPENSES_TYPE_FAILED = "GET_EXPENSES_TYPE_FAILED";

export const POST_EXPENSES_STARTED = "POST_EXPENSES_STARTED";
export const POST_EXPENSES_SUCCESSFUL = "POST_EXPENSES_SUCCESSFUL";
export const POST_EXPENSES_FAILED = "POST_EXPENSES_FAILED";

export const DELETE_EXPENSES_STARTED = "DELETE_EXPENSES_STARTED";
export const DELETE_EXPENSES_SUCCESSFUL = "DELETE_EXPENSES_SUCCESSFUL";
export const DELETE_EXPENSES_FAILED = "DELETE_EXPENSES_FAILED";

export const UPDATE_EXPENSES_STARTED = "UPDATE_EXPENSES_STARTED";
export const UPDATE_EXPENSES_SUCCESSFUL = "UPDATE_EXPENSES_SUCCESSFUL";
export const UPDATE_EXPENSES_FAILED = "UPDATE_EXPENSES_FAILED";

export const WITHDRAW_STARTED = "WITHDRAW_STARTED";
export const WITHDRAW_SUCCESSFUL = "WITHDRAW_SUCCESSFUL";
export const WITHDRAW_FAILED = "WITHDRAW_FAILED";

export const VERIFY_STARTED = "VERIFY_STARTED";
export const VERIFY_SUCCESSFUL = "VERIFY_SUCCESSFUL";
export const VERIFY_FAILED = "VERIFY_FAILED";

export const POST_USER_STARTED = "POST_USER_STARTED";
export const POST_USER_SUCCESSFUL = "POST_USER_SUCCESSFUL";
export const POST_USER_FAILED = "POST_USER_FAILED";

export const GENERATE_REPORT_STARTED = "GENERATE_REPORT_STARTED";
export const GENERATE_REPORT_SUCCESSFUL = "GENERATE_REPORT_SUCCESSFUL";
export const GENERATE_REPORT_FAILED = "GENERATE_REPORT_FAILED";

export const GET_USERS_STARTED = "GET_USERS_STARTED";
export const GET_USERS_SUCCESSFUL = "GET_USERS_SUCCESSFUL";
export const GET_USERS_FAILED = "GET_USERS_FAILED";
