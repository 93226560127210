import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formatNumber } from "../Utils/helpers";
import { useEffect } from "react";

const UserItem = ({ style, data, auth, toggle, changePassword }) => {
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const [user, setUser] = useState(data);

  useEffect(() => {
    setUser(user);
  }, [data]);

  return (
    <tr
      className={`dgItemStyle txt-c ${style} ${
        user.id === auth?.id ? "hide" : ""
      }`}
      key={user.id}
    >
      <td onClick={() => toggle(user)}>
        {user.rolename !== "Cashier" && user.rolename !== "Player" && (
          <button style={{ cursor: "pointer", marginRight: 5 }}>
            {user.expanded ? "-" : "+"}
          </button>
        )}
        {user.code}
      </td>
      <td>{user.rolename}</td>
      <td>{user.username}</td>
      <td>{user.email}</td>
      <td style={{ textAlign: "left" }}>
        {SportsbookGlobalVariable.Currency}
        {formatNumber(user.balance)}
      </td>
      <td style={{ textAlign: "left" }}>
        <a href="javascript:;" onClick={() => changePassword(user)}>
          <img src="/img/password.png" alt="" />
        </a>
      </td>
    </tr>
  );
};

export default UserItem;
