import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SHOW_MODAL, CASHIN_DETAILS, CASHOUT_DETAILS } from "../../Redux/types";
import { formatNumber } from "../../Utils/helpers";
import { useHistory } from "react-router-dom";
import {
  approveCashin,
  approveCashout,
  getCashIn,
  getCashOuts,
} from "../../Services/apis";

const Confirm = ({ title, toggle }) => {
  const router = useHistory();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const item = JSON.parse(title);

  const fetchCashin = async () => {
    const cashIn = await getCashIn();
    if (cashIn.success) {
      dispatch({
        type: CASHIN_DETAILS,
        payload: {
          cashIn: cashIn.data,
        },
      });
    }
  };

  const fetchCashout = async () => {
    const cashout = await getCashOuts();
    if (cashout.success) {
      dispatch({
        type: CASHOUT_DETAILS,
        payload: {
          cashout: cashout.data,
        },
      });
    }
  };
  const handleConfirm = async () => {
    if (item.type === "cashin") {
      const isCashin = await approveCashin({
        id: item.id,
        status: 1,
      });

      if (isCashin.success) {
        fetchCashin();
        toast.success(isCashin.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      } else {
        toast.error(isCashin.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      }
    }
    if (item.type === "cashout") {
      const isCashout = await approveCashout({
        id: item.id,
        status: 1,
      });
      fetchCashout();
      if (isCashout.success) {
        toast.success(isCashout.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      } else {
        toast.error(isCashout.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      }
    }
  };

  return (
    <div className="expense-form">
      <div className="expense-input">
        {/* <label>Proceed to Cashbook to Handle Report</label> */}
        <label className="" style={{ textTransform: "capitalize" }}>
          Would you like to confirm
        </label>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space between",
            alignItems: "center",
            gap: ".4rem",
          }}
          className=""
        >
          <button
            className="green"
            onClick={() => {
              dispatch({ type: SHOW_MODAL, payload: null });
            }}
            style={{
              background: "#000229",
              color: "white",
              marginRight: "1rem",
              border: "0px",
              padding: ".2rem 1rem",
            }}
          >
            No
          </button>
          <button
            className="green"
            onClick={() => {
              handleConfirm();
              dispatch({ type: SHOW_MODAL, payload: null });
            }}
            style={{
              background: "#000229",
              color: "white",
              marginRight: "1rem",
              border: "0px",
              padding: ".2rem 1rem",
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
