import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMonthlyBonus } from "../Services/apis";
import { formatDate } from "../Utils/helpers";
import "../Styles/_bonus.scss";
import { BonusMathsCard } from "./BonusMathsCard";
import { BonusInformation } from "./BonusInformation";

/**
 * packages
 */

export const MonthlyBonus = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [object, setObject] = useState({});

  const fetchBonus = () => {
    setLoading(true);
    // const dates = {
    //   from: formatDate(inputObject?.startDate, "DD-MM-YYYY"),
    //   to: formatDate(inputObject?.endDate, "DD-MM-YYYY"),
    //   type: activeTab === "all" ? " " : activeTab,
    // };
    getMonthlyBonus()
      .then((res) => {
        console.log(res);
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBonus();
  }, [dispatch]);

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...object,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="bonus ">
      <div className="content">
        <h4 className="bonus-title">Monthly Bonus</h4>
        <div>
          <p className="bonus-info">
            {" "}
            <strong>NOTE:</strong> We have made some exciting new changes to
            bonus commissions which improves your earning potential. Along with
            these changes, data prior to 28 Nov 2022 will not be available on
            the report below. To get this data, please contact your Agent
            Support Team on as@betking.com who will gladly send you historic
            data for up to 3 months prior.
          </p>
        </div>
        <div className="table-box">
          <table className="bonus-table">
            {data && (
              <tr>
                <th>Channel Name</th>
                <th>Settled Date</th>
                <th>Settled Bets</th>
                <th>No of Selections</th>
                <th>Stake (N)</th>
                <th>Winnings (N)</th>
                <th>WeightedStake (N)</th>
              </tr>
            )}
            {data ? (
              <>
                {data?.bets?.map((item, i) => (
                  <tr className="main" key={i}>
                    <td
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {item?.channel}
                    </td>
                    <td>{formatDate(item?.settled_at, "DD-MM-YYYY")}</td>
                    <td>{item?.bet_info?.length}</td>
                    <td>{item?.selections_count}</td>
                    <td>{item?.stake}</td>
                    <td>{item?.winnings}</td>
                    <td>{item?.commission}</td>
                  </tr>
                ))}
                <tr className="total">
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td>{data?.totalAverageSelections}</td>
                  <td>{data?.totalSales}</td>
                  <td>{data?.totalWinnings}</td>
                  <td>{data?.totalWeightedStake}</td>
                </tr>
              </>
            ) : (
              <div>{/* <NotFound /> */}</div>
            )}
          </table>
        </div>
        <div className="flex">
          <BonusMathsCard
            title="Weighted Stake Calculation"
            firstLabel="Stake"
            secondLabel="No. of Selection"
            thirdLabel="Weighted Stake"
            firstValue={data?.bets[0]?.selections_count}
            secondValue={data?.bets[0]?.stake}
            symbol={"X"}
            total={data?.bets[0]?.selections_count * data?.bets[0]?.stake}
          />
          <BonusMathsCard
            title="Gross Profit Calculation"
            firstLabel="Total Stake"
            secondLabel="Total Winnings"
            thirdLabel="Gross Profit"
            firstValue={data?.totalSales}
            secondValue={data?.totalWinnings}
            symbol={"-"}
            total={data?.totalSales - data?.totalWinnings}
          />
        </div>
        <div className="flex">
          <BonusMathsCard
            title="Avg. No. os Selections Calculation "
            firstLabel="Total W. Stake"
            secondLabel="Total Stake"
            thirdLabel="Avg. No. of S."
            firstValue={data?.totalWeightedStake}
            secondValue={data?.totalSales}
            symbol={"/"}
            total={data?.totalWeightedStake / data?.totalSales}
          />
          <BonusMathsCard
            title="Gross Profit Percentage Breakdown"
            firstLabel="GGR %"
            secondLabel="Gross Profit"
            thirdLabel="Total"
            firstValue={data?.GGR}
            secondValue={data?.totalGrossProfit}
            symbol={"X "}
            total={data?.GGR * data?.totalGrossProfit}
          />
        </div>
        {/* <div className="">
          <BonusMathsCard
            propsClass="full"
            title="Bonus Calculation"
            firstLabel="5% of Gross Profit"
            secondLabel="Total Paid Commission"
            thirdLabel="Bonus"
            fourthLabel="Tot. Est. Commission"
            firstValue={data?.GGR}
            secondValue={data?.totalGrossProfit}
            thirdValue={data?.totalGrossProfit}
            symbol={"X "}
            total={data?.GGR * data?.totalGrossProfit}
          />
        </div> */}
        <BonusInformation />
      </div>
    </div>
  );
};
